import React, { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import classes from './Testimonials.module.css';


const testimonials = [
  {
    firstName: 'Jana',
    lastName: 'Wemhöner',
    comment: 'One piece that was waiting in my closet changed...',
  },
  {
    firstName: 'Michael',
    lastName: 'Geke',
    comment: 'My old leather briefcases look like new...',
  },
  {
    firstName: 'Anja',
    lastName: 'Gräf',
    comment: 'So pleased with the excellent repair of my...',
  },
  {
    firstName: 'Aline',
    lastName: 'GERKEN',
    comment: 'I wanted something new and fresh...',
  },
  {
    firstName: 'ANDREA',
    lastName: 'APELT',
    comment: 'I am very excited! Had not thought that...',
  },
  {
    firstName: 'KEVIN',
    lastName: 'KOCK',
    comment: 'My favorite jeans were worn-out in the crotch area...',
  },
  {
    firstName: 'ELENA',
    lastName: 'ZHIGALINA',
    comment: 'The My experience with Repair Rebels was very...',
  },
  {
    firstName: 'ULRIKE',
    lastName: 'BELL-KIRCHHOFF',
    comment: 'The digital ordering process is great and we...',
  },
  {
    firstName: 'CLAUDIA',
    lastName: 'MEINKENS',
    comment: 'Many thanks to the team of Repair Rebels. My beloved...',
  },
  {
    firstName: 'STEPHANIE',
    lastName: 'STEINCHEN',
    comment: 'We are totally thrilled with your service!!!...',
  },
  {
    firstName: 'BIRGITT',
    lastName: 'HEDLEFS',
    comment: 'I thought that my hiking boots were lost. But they are...',
  },
  {
    firstName: 'ARNE',
    lastName: 'WEYRES',
    comment: 'Great job! You just made the hole invisible. It was super...',
  },
];



export default function Testimonials() {
  const [testimonialRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const sliderRef = useRef(null);

  const { t } = useTranslation();
  SwiperCore.use([Navigation]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const createSlides = (item, i) => {
    return (
      <SwiperSlide key={i}>
        <div key={i}>
          <div style={{ animationDelay: `.4s` }} className={`${classes.testimonial} ${isVisible ? classes.animation : ''}`}>
            <div className={classes.feedbackContainer}>
              <div className={classes.feedback}>
                <div className={classes.feedbackText}>
                  <span>{t(item.comment)}</span>
                </div>
                <div className={classes.feedbackName}>
                  <div className={classes.feedbackFirstName}>- {item.firstName}</div>
                  <div className={classes.feedbackLastName}>{item.lastName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    )
  }


  return (
    <div ref={testimonialRef} className="container">
      <h2 className={`h4 with-bar mb-3 ${classes.title} ${isVisible ? classes.titleAnimation : ''}`}>{t('Our Rebels')}</h2>
      <div className={`row ${classes.testimonialsContainer}`}>
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          loop
          pagination={{
            clickable: true,
          }}
          spaceBetween={40}
          className="gallerySwiper"
          breakpoints={{
            922: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {testimonials.map((image, i) => createSlides(image, i))}
        </Swiper>
        <div className={`swiper-button-prev ${classes.navigationButton}`} onClick={handlePrev} />
        <div className={`swiper-button-next ${classes.navigationButton}`} onClick={handleNext} />
      </div>
    </div>
  );
}
