import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { useCartApi } from '../../../Cart/utils';
import AppNotFound from '../../../components/NotFound/NotFound';
import { getProduct } from '../../utils';
import ProductDetailsStepForm from './Form';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from '../../../interfaces/app-state';
import { Product } from '../../../types';
import i18n from 'i18n';

interface ProductDetailsStepRouteProps {
  seo: string;
  typeId: string;
  repairId: string;
}
interface ProductDetailsStepProps extends RouteComponentProps<ProductDetailsStepRouteProps> {
  products: Product[];
}

/**
 * "Further Details" component for a new garment.
 */
export default function ProductDetailsStep({ products, seo, typeId, repairId }: ProductDetailsStepProps) {
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  const cartApi = useCartApi();
  const navigate = useNavigate();
  const product = getProduct(products, seo ?? '', typeId ?? '', repairId ?? '');
  
  if (!product) {
    return <AppNotFound />;
  }

  const handleSubmit = async (amount?: number, comment?: string, creationTime?: number, images?: string[]) => {
    const { productType } = await cartApi.addItem(product._id, !!user._id, amount, comment, creationTime, images);
    navigate(`../../success/${productType.seo?.[i18n.language]}`);
    // possible error is caught by the `onSubmit` itself
  };

  return <ProductDetailsStepForm product={product} onSubmit={handleSubmit} />;
}
