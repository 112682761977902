import appProductsI18nDe from './products/de';
import appTailorsI18nDe from './tailors/de';
import seoI18nDe from './seo-de';

// const appI18nDe = {
//   'Unexpected error. Try again later or contact us': 'Unerwarteter Fehler. Versuchst Du es später erneut oder kontaktiere uns',
//   'Order access error': 'Bestellung nicht gefunden oder Du bist nicht berechtigt, sie zu bearbeiten. Bitte prüfe, ob Du eingeloggt bist oder kontaktiere uns!',
//   'Order rating access error':
//     'Bestellung nicht gefunden oder Du bist nicht berechtigt, Schneider zu bewerten. Bitte prüfe, ob Du eingeloggt bist oder kontaktiere uns!',
//   'Not Found': 'Nicht Gefunden',
//   'Go back': 'Geh zurück',
//   'Frequently Asked Questions': 'Häufig gestellte Fragen',
//   'Our Supporters': 'Unsere Unterstützer',
//   'Keep them and let us take care of them!': 'Behalte sie und lasse sie durch uns reparieren.',
//   'Still in love with your favourites?': 'Du hängst an Deinen Lieblingsstücken?',
//   'Door to door deliveries': 'Lieferung von Tür zu Tür in',
//   'Not in': 'Nicht in',
//   Within: 'Innerhalb von',
//   'We pick it up and we deliver it back': 'holen wir Deine Lieblingsstücke ab und stellen sie wieder zu',
//   'Tuesdays & Fridays': 'Dienstags und freitags',
//   'Pick ups and deliveries <1>only in Düsseldorf</1>': 'Abholung und Lieferung <1>nur in DÜSSELDORF</1>',
//   'One week only!': 'Nur 7 Tage',
//   'Get your garment back within a week': 'Bekomme Deine Lieblingsstücke innerhalb von 7 Tagen zurück',
//   'Loading...': 'Laden..',
//   'Get estimation': 'Schätzung anfordern',
//   'Get estimation description':
//     'Kein passendes Angebot dabei oder hast Du eine besondere Reparatur? Fordere jetzt eine Schätzung an. Wir möchten uns um jedes Lieblingsstück kümmern und Dir ein individuelles Angebot schicken.',
//   estimationDataPrivacy:
//     'Ich willige ausdrücklich in die Erhebung und Verarbeitung meiner personenbezogenen Daten zur Schätzung der Kosten, welche durch die angefragte Reparaturleistung entstehen, ein. Alle Angaben sind freiwillig. Die Hinweise zum Datenschutz, die unter den <0>Link</0>, abrufbar sind, habe ich zur Kenntnis genommen*',
//   ratingDataPrivacy:
//     'Ich willige ausdrücklich in die Erhebung, Verarbeitung und Veröffentlichung meiner personenbezogenen Daten zur Bewertung der Leistung ein. Alle Angaben sind freiwillig. Die Hinweise zum Datenschutz, die unter den <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen*',
//   contactUsDataPrivacy:
//     'Ich willige ausdrücklich in die Erhebung und Verarbeitung meiner personenbezogenen Daten zur Kontaktaufnahme ein. Die Hinweise zum Datenschutz, die unter den <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen.',
//   "Let's get started!": 'Los geht’s!',
//   'Choose the item to repair': 'Wähle den Artikel aus, für den du eine Reparatur benötigst',
//   Dresses: 'Kleider',
//   Trousers: 'Hosen',
//   Tops: 'Oberteile',
//   Jackets: 'Jacken',
//   Sneakers: 'Sneaker',
//   Bags: 'Taschen',
//   '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>': '<0><0>Gut für die Umwelt</0><1></1></0><1>& gut für deinen Geldbeutel!</1>',
//   'Collect CO2 Credits and get discounts': 'Sammle CO2 Credits und erhalte Rabatte.',
//   'Learn More': 'erfahre mehr',
//   'Try it now!': 'Los geht´s!',
//   '<0>Want to become a</0><1>Repair Rebel?</1>': '<0>Werde ein</0><1>Repair Rebel!</1>',
//   'Why <1></1> Repair Rebels?': 'Warum <1></1> Repair Rebels?',
//   homePageLoyaltyClubBody:
//     'Erhalte praktische Tipps, exklusive Angebote und sammle CO2 Credits. Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
//   homePageLoyaltyClubDescriptiveParagraphHeader: 'Das erwartet Dich: ',
//   homePageLoyaltyClubDescriptiveParagraph:
//     'Viele nützliche Tipps und Tricks wie Du Deine geliebten Kleidungsstücke, Schuhe und Taschen länger nutzen kannst. Coole Facts und Infos zum Thema Mode, Nachhaltigkeit und Events in Deiner Nähe. Und wie sollte es anders sein, natürlich wirst Du für Deine Treue und Leidenschaft für Reparaturen auch mit Rabatten und Treuepunkten belohnt. Sei dabei und verpasse keine Neuigkeiten mehr!',
//   homePageLoyaltyClubCallToAction: 'Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
//   homePageLoyaltyClubQuote: 'Du möchtest ein echter Repair Rebel werden? Dann werde Teil des Super Rebel Loyalty Clubs.',
//   'Check your inbox for the invite email': 'Prüfe deinen Posteingang auf die Einladungs-E-Mail',
//   'Invite sent': 'Einladung verschickt',
//   'You are subscribed': 'Hooray! Du hast Dich erfolgreich für den Newsletter des Super Rebel Loyalty Clubs angemeldet.',
//   'Discounts & Packages': 'Rabatte & Gutscheine',
//   'Join the club': 'Trete dem Repair Rebel Loyalty Club bei',
//   Back: 'Zurück',
//   'Our Rebels': 'Das sagen unsere Kunden',
//   'See More': 'Mehr',
//   'Our Tailors': 'Unsere Partner vor Ort',
//   'Meet our local partners': 'Lerne unsere Partner und ihre Geschichten kennen',
//   homePageBlogIntro:
//     'Unsere lokalen Partner unterstützen unsere Mission, Deinen Kleidungsstücken ein Update zu geben, sodass Du sie länger tragen kannst. Unsere ausgewählten SchneiderInnen und Schuster sind Meister ihres Faches und zeigen Leidenschaft und Liebe bei jeder Reparatur.',
//   'Contact Information': 'Kontaktinformation:',
//   'Contact Information - Bodytext 1':
//     'Wir sind auf der Mission, Textil- und Schuhreparaturen bequem, sozial und modisch zu gestalten! Für die Umwelt, stilvolle Rebellen und für lokale Talente.',
//   'Contact Information - Contact Us': 'Kontaktiere uns für weitere Informationen:',
//   'Contact Information Estimation': 'Kontaktdaten',
//   Imprint: 'Impressum',
//   'Privacy Policy': 'Datenschutz',
//   'Terms and Conditions': 'AGB',
//   // Detailed image button
//   from: 'ab',
//   'Main services': 'Dienste',
//   Address: 'Adresse',
//   'Name for the Address': 'Namen für die Adresse',
//   'Address line': 'Adresszeile',
//   'Zip code': 'Zip code',
//   'Pictures of his work': 'Bilder seiner Arbeit',
//   'Pictures of her work': 'Bilder ihrer Arbeit',
//   'Good for the planet': 'Gut für die <br /> Umwelt',
//   'Good for your wallet': 'Gut für deinen <br /> Geldbeutel',
//   'Good for the local economy': 'Gut für die lokale <br /> Wirtschaft',
//   'CO2 Credits Per Garment': 'CO<sub>2</sub> Credits',
//   Garment: 'Kleidungsstück',
//   'CO2 Credits': 'CO<sub>2</sub> Credits',
//   'Why CO2 Credits?': 'Wozu CO<sub>2</sub> Credits?',
//   CO2_page_body:
//     '<p>Unsere Mission ist es, deine Garderobe nachhaltiger zu gestalten. Wenn du deine Lieblingsstücke länger behältst und pflegst, sparst du Zeit, Geld und gleichzeitig jede Menge CO2 und natürliche Ressourcen.</p><p>Mit den CO2 Credits kannst du sehen, wie viel CO2 du mit der Reparatur im Vergleich zum Neukauf eingespart hast. Mit den gesammelten Credits aktivierst du außerdem auch Rabatte und Gutscheine. Also los geht‘s!</p>',
//   'Start Repairing': 'Jetzt Reparieren!',
//   'START REPAIRING': 'REPARIEREN',
//   'PRESS PACKAGE': 'PRESSEPAKET',
//   'PRESS TEXT': 'MÖCHTEST DU MEHR ÜBER UNSERE ARBEIT ERFAHREN? SCHAUE UNSER PRESSEPAKET AN, IN DEM DU UNSERE PRESSEMITTEILUNGEN UND FOTOS FINDEST',
//   'Join the Club': 'Ich möchte dem Super Rebel Loyalty Club beitreten!',
//   'How It Works': 'FAQs',
//   'About Us': 'Über Uns',
//   'Tips & More': 'Tipps & Mehr',
//   Tailors: 'Unsere Partner',
//   Contact: 'Kontakt',
//   Repairing: 'Reparieren',
//   //"Choose the item to repair": "Wähle den zu reparierenden Artikel",  // DUPLICATE; TODO: choose correct translation
//   'Select the item type': 'Wähle den Artikeltyp',
//   'Select the type of repair': 'Wähle die gewünschte Reparatur',
//   'Select the type of service': 'Wähle die gewünschte Dienstleistung',
//   'Further Details': 'Weitere Details',
//   'Send us your request': 'Schicke uns Deine Anfrage',
//   'Add to Cart': 'In den Warenkorb',
//   Adding: 'Warte', // NOT SURE ABOUT THIS
//   Comments: 'Kommentare',
//   'Type of Repair': 'Reparatur',
//   'Item Type': 'Artikel',
//   product_estimation_hint:
//     'Kein passendes Angebot dabei oder hast Du eine besondere Reparatur? Fordere jetzt eine Schätzung an. Von Omas Mantel bis hin zu Deinem Camping Zelt, wir schauen uns jede Anfrage individuell an und suchen einen passenden Repairkünstler aus.',
//   'Garment Description': 'Beschreibung des Kleidungsstücks',
//   'Describe your requests for repair': 'Beschreibe Deine Reparatur oder Änderungs-Wünsche',
//   Images: 'Bilder',
//   'Images are really important for this process':
//     'Um Dir ein zuverlässiges Angebot zuschicken zu können, brauchen wir eindeutige Fotos Deines Lieblingsstückes. Bitte lade hier Fotos von',
//   estimation_request_form_image_hint: 'Bilder sind für diesen Prozess wirklich wichtig. Bitte fügen Sie klare und spezifische Fotos des Fehlers bei.',
//   'Upload Image': 'Bilder anhängen',
//   'Send Request': 'Abschicken',
//   'Your request for a personal offer has been sent': 'Deine Anfrage für ein persönliches Angebot wurde verschickt',
//   'Together with our tailors and shoemakers':
//     'Wir schauen nun gemeinsam mit unseren Schneidern und Schuster, was wir für Dein Lieblingsstück tun können und melden uns bald bei Dir',
//   'Book further repair': 'Weitere Reparatur buchen',
//   'Back to homepage': 'Zurück zur Startseite',
//   // from here I stop adding obvious English keys, thus relying on them as on default ones
//   Description: 'Beschreibung',
//   Title: 'Titel',
//   sizeError: 'Die Upload-Dateigröße ist größer als 50 MB. Verwenden Sie ein anderes Bild',
//   Name: 'Name',
//   Email: 'E-Mail',
//   Gallery: 'Gallerie',
//   'Contact Us': 'Kontakt',
//   Subject: 'Betreff',
//   'Your phone number': 'Deine Telefonnummer ( +4917669423504 )',
//   'Send Message': 'Senden',
//   'Thank you Repair Rebel': 'Vielen Dank<0/> Repair Rebel!',
//   'Go back to home': 'Zurück zur Startseite',
//   contact_form_hint: 'Einer unserer Agenten wird sich so schnell wie möglich bei Ihnen melden.',
//   contact_form_banner_text: 'Ihre Anfrage wurde gesendet.',
//   order_status_view_hint: 'Du erhältst in Kürze eine E-Mail-Bestätigung mit weiteren Details und Anweisungen.',
//   'Your name': 'Dein Name',
//   'Your email address': 'Deine E-Mail-Adresse',
//   'Your message': 'Deine Nachricht',
//   'Send us an email': 'Sende uns eine E-Mail',
//   'Sign In': 'Login',
//   Register: 'Jetzt Registrieren',
//   "Don't have an account yet?": 'Du hast noch keinen Account?',
//   Password: 'Passwort',
//   'Forgot your password?': 'Passwort vergessen?',
//   'Register now': 'Registriere dich jetzt',
//   'Forgotten Password': 'Passwort vergessen?',
//   Send: 'Absenden',
//   auth_password_reset_hint:
//     'Gib die E-Mail-Adresse ein, mit der du dein Repair Rebel Konto erstellt hast. Wir schicken dir an diese Adresse einen Link zum Zurücksetzen deines Passwortes.',
//   Guest: 'Gast',
//   'First Name': 'Vorname',
//   'Last Name': 'Nachname',
//   'Phone Number': 'Telefonnummer',
//   'Confirm Password': 'Bestätige dein Passwort',
//   auth_register_submit_label: 'Jetzt Registrieren',
//   auth_register_login_heading: 'Du hast bereits ein Kundenkonto?',
//   auth_register_heading: 'Registriere dich',
//   auth_register_join_club_label: 'Ich möchte dem Repair Rebel Loyalty Club beitreten',
//   auth_register_join_club_sublabel: 'Sammle CO2 Credits und erhalte Rabatte und Gutscheine',
//   auth_register_accept_terms_label:
//     'Hiermit erkläre ich mich mit den <0>Datenschutzbestimmungen</0>, <1>AGB und Widerrufsbestimmungen</1> von Repair Rebels einverstanden*',
//   fields_with_asterisks: 'Die mit einem (*) markierten Felder sind Pflichtfelder.',
//   newsletter_clarification:
//     '¹ Du kannst dich jederzeit wieder vom Newsletter abmelden. Mit Setzen des Hakens erkläre ich mich einverstanden, dass die von mir erhobenen Daten für die Bearbeitung meiner Anfrage elektronisch erhoben und gespeichert werden. Diese Einwilligung kann jederzeit mit einer Nachricht an uns widerrufen werden. Weitere Informationen kannst du der Datenschutzerklärung entnehmen.',
//   auth_forgot_password_success_text:
//     'Ein Link zum Zurücksetzen des Passworts wurde gesendet. Um Ihr Passwort zurückzusetzen, öffnen Sie bitte den Link und folgen Sie den Anweisungen.',
//   'Change Password': 'Passwort ändern',
//   'Current Password': 'Jetziges Passwort',
//   'New Password': 'Neues Passwort',
//   Change: 'Aktualisieren',
//   Code: 'Kode',
//   Jobs: 'Arbeitsplätze',
//   'Become a repair rebel': 'Werde ein Repair Rebel!',
//   'Password has been changed': 'Ihr passwort wurde erfolgreich geändert!',
//   Close: 'Schließen',
//   Delete: 'Löschen',
//   'Are you sure': 'Bist du sicher',
//   'Add Address': 'Adresse hinzufügen',
//   'My Addresses': 'Meine Adressen',
//   'Current addresses': 'Aktuelle adressen',
//   Add: 'Hinzufügen',
//   'Add New Payment Method': 'Neue Zahlungsmethode hinzufügen',
//   'Payment Methods': 'Zahlungsarten',
//   'Card Number': 'Kartennummer',
//   'Expiry Month': 'Monat ablaufen',
//   'Expiry Year': 'Ablaufjahr',
//   'Visa endings with': 'Visa endet mit',
//   Edit: 'Bearbeiten',
//   Save: 'Speichern',
//   Confirm: 'Speichern',
//   product_further_details_frame_heading: 'Hey Rebel!<br />Toll, dass du eine Reparatur buchen möchtest!',
//   product_further_details_frame_hint: 'Wir optimieren grade unser online Buchungssystem. In der Zwischenzeit nehmen wir Bestellungen über WhatsApp an:',
//   'Because everyone and everything deserves a second chance': 'Weil jeder und alles eine zweite Chance verdient',

//   'How was the tailors performance?': "Wie war der Service von {{tailorName}}'?",
//   'Your comment': 'Your comment...', // ENGLISH
//   'Please rate our service': 'Bitte bewerte unsere Leistung',
//   'How were things managed by us?': 'Wie war der Service von Repair Rebels?',
//   'Rate your tailor(s)/cobbler(s)': 'Bitte bewerte Deinen Schneider/Schuster',

//   'There are no addresses': 'Es sind keine Adressen vorhanden',
//   'Hey there, super rebel': 'Hey Du, Super Rebel!',
//   'You are part of our loyalty club': 'Du bist Teil unseres Treueclubs',
//   'Your CO2 points balance': 'Deine CO2-Punktebilanz:',
//   'Check your benefits': 'Überprüfe Deine Vorteile',
//   'Join our Rebel team!': 'Werde Teil unseres Rebellen-Teams!',
//   'Add another garment': 'Weitere Artikel reparieren',
//   'Go to cart': 'Zum Warenkorb',
//   'Proceed to payment': 'Fahren Sie mit der Zahlung fort',
//   'Courier Service': 'Kurier Service (ab 125€ Bestellwert kostenlos)',
//   'Packaging Cost': 'Verpackungskosten',
//   'Final Price': 'Total',
//   inclVAT: 'inkl. MwSt.',
//   'Order as a Guest': 'Als Gast bestellen',
//   Or: 'Oder',
//   Location: 'Ort',
//   rating_success_heading: 'Hurray, Repair Rebel!',
//   rating_success_hint: 'Wir schätzen Deine Meinung wirklich sehr und wir arbeiten daran, jeden Tag besser zu werden!',
//   'The idea of Repair Rebels was born from':
//     'Die Idee für Repair Rebels ist aus der Liebe zur Mode und der Überzeugung, dass unsere Kleidungsstücke mehr Wertschätzung verdienen, entstanden. Wir machen unsere Erfahrungen und Erlebnisse in unserer Kleidung, so können mit einem Kleidungsstück zahlreiche Erinnerungen und Gefühle verbunden sein. Der wahre Wert unserer Kleidung wird nicht im Geschäft entschieden - wir geben ihr den Wert, indem wir sie lieben, tragen, in ihr leben und uns um sie kümmern. Dabei machen ihre kleinen Makel sie so besonders und spiegeln unsere Einzigartigkeit wider.',
//   "That's why we want to rebel the fashion system":
//     'Deshalb möchten wir das Modesystem rebellieren und Deine Lieblingsstücke nicht ersetzen, sondern reparieren. Dazu trifft auf unserer Plattform Technologie auf traditionelles Handwerk. Wir wollen die Welt zu einem besseren Ort machen, indem wir die schnelllebige Modeindustrie und den Konsumerismus bremsen. Beinahe die Hälfte der Deutschen hat noch nie ein Kleidungsstück oder ein paar Schuhe reparieren lassen (Greenpeace, 2022). Stattdessen bestellen die meisten Konsumenten online neue Kleidung und ersetzen so ihre Lieblingsstücke. Wir wollen die Wertschätzung für die Handarbeit und den Respekt für Dinge, die wir bereits besitzen, fördern und das lokale Handwerk unterstützen.',
//   'Repairing is an attitude':
//     'Reparieren ist eine Haltung - eine Lebensphilosophie. Wenn wir unsere Kleidung reparieren können, können alles im Leben reparieren',
//   'Fast fashion was yesterday':
//     'Fast Fashion war gestern, Slow Fashion und damit auch Reparaturen sind die Zukunft. Reparaturen unserer Lieblingsstücke sparen uns nicht nur viel Zeit und CO2 Emissionen, sondern sie sind auch günstiger und schaffen dabei lokale Arbeitsplätze. Indem Du Deine Sneaker, Jeans, Lederjacke oder auch jedes andere Lieblingsstück länger behältst, sammelt ihr gemeinsam Erinnerungen und Du kannst ein Style-Statement setzen. Wir machen Textil- und Schuhreparaturen so modisch und einfach wie den Kauf neuer Artikel - nur günstiger, nachhaltiger und lokaler. Reparieren war noch nie so digital und so sexy. So kannst Du bequem vom Sofa aus eine Reparatur buchen. Das Kleidungsstück wird zum gewünschten Zeitpunkt bei Dir abgeholt und einige Tage später wieder repariert zugestellt. Wir bieten einen digitalen Rundumservice inklusive Abholung und Zustellung. So wird Repair Rebels zu einer echten Alternative zum Neukauf!',
//   "Sounds good, doesn't it? Show love & repair your favorites!": 'Klingt gut, oder? Show love & repair your favourites!',
//   'We should rethink our relationship...':
//     'Wir sollten unsere Beziehung zu unserer Kleidung neu denken und sie wie eine zweite Haut behandeln und sie pflegen.',
//   'Repair now!': 'Jetzt reparieren!',
//   'I want to join the super rebel loyalty club!': 'Ich möchte dem super rebel loyalty club beitreten!',
//   'The idea of Repair Rebels was born out of a love for fashion...':
//     'Die Idee für Repair Rebels ist aus der Liebe zur Mode und der Überzeugung, dass unsere Kleidungsstücke mehr Wertschätzung verdienen, entstanden. Wir machen unsere Erfahrungen und Erlebnisse in unserer Kleidung, so können mit einem Kleidungsstück zahlreiche Erinnerungen verbunden werden. Das macht es um so wichtiger, unsere Kleidung von einem Local Hero retten zu lassen. So geben wir jedem Kleidungsstück eine zweite Chance uns bei vielen weiteren glücklichen Momenten zu begleiten.',
//   "But of course, we don't accomplish all of this alone...":
//     'Aber all das erreichen wir natürlich nicht alleine! Wenn Du Teil unserer Mission werden möchtest, bist Du hier genau richtig!',
//   'Are you looking for a dynamic and impact-driven company...':
//     'Du suchst ein dynamisches und Impact-Driven Unternehmen, das die Modebranche revolutionieren möchte und sich stetig weiterentwickelt? Dann bist Du hier genau richtig! Als Teil unseres internationalen und ambitionierten Teams arbeitest Du an einer Pionierlösung, um die lokalen Handwerksindustrie zu digitalisieren und gestaltest aktiv die Zukunft der Modeindustrie.',
//   'Not in Düsseldorf?': 'Nicht in Düsseldorf?',
//   'Book your repairs via "Estimate Request" and send it to us via post': 'Dann buche über "Schätzung anfordern" und verschicke Dein Paket per Post',
//   'Select the item for which you need a repair.': 'Wähle den Artikel aus, für den Du eine Reparatur benötigst.',
//   'Pick-up and delivery is free of charge for orders over 125€.': 'Abholung und Zustellung in Düsseldorf ab 125€ Bestellwert kostenlos!',
//   'send us your question(s)': 'Stell uns deine Frage(n)',
//   "Send us a WhatsApp message and we'll see what we can do for you!": 'Schicke uns eine WhatsApp Nachricht und wir schauen, was wir für Dich tun können!',
//   'Please give us your pick up and delivery information for this booking': 'Bitte geben Sie uns Ihre Abhol- und Lieferinformationen für diese Buchung',
//   Dusseldorf: 'Düsseldorf',
//   Cologne: 'Köln',
//   Essen: 'Essen',
//   'Choose your location': 'Wählen Sie Ihren Standort',
//   'Invalid zip code': 'Ungültige Postleitzahl',
//   Unauthorized: 'Unerlaubter',
//   'The address id is not defined': 'Die Adress-ID ist nicht definiert',
//   'Internal Server Error': 'Interner Serverfehler',
//   'A customer document with the provided id does not exists': 'Ein Kundendokument mit der angegebenen ID existiert nicht',
//   'Oops, something went wrong': 'Uups, etwas ist schief gelaufen',
//   'Something went wrong': 'Etwas ist schief gelaufen',
//   'Please, reload the page to fix it': 'Bitte laden Sie die Seite neu, um sie zu beheben',
//   Reload: 'Neu laden',
//   Success: 'Erfolg',
//   'Sign Out': 'Ausloggen',
//   Profile: 'Profil',
//   'Invalid email': 'Ungültige E-Mail',
//   'Update Password': 'Passwort Aktualisieren',
//   'Delete Account': 'Konto löschen',
//   removeUserAccountDetails: 'Sie werden Ihr Konto vollständig entfernen. Aktivieren Sie das Kontrollkästchen, um Ihre Aktionen zu bestätigen.',
//   'Your account has been successfully deleted': 'Ihr Konto wurde erfolgreich gelöscht. Sie werden weitergeleitet.',
//   'The user account has been deleted. Use another email to sign up or contact our support team.':
//     'Das Benutzerkonto wurde gelöscht. Verwenden Sie eine andere E-Mail-Adresse, um sich anzumelden, oder wenden Sie sich an unser Support-Team.',
//   'The user account has been disabled by an administrator.':
//     'Das Benutzerkonto wurde gelöscht. Verwenden Sie eine andere E-Mail-Adresse, um sich anzumelden, oder wenden Sie sich an unser Support-Team.',
//   'There is no user record corresponding to this identifier. The user may have been deleted.':
//     'Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer wurde möglicherweise gelöscht.',
//   'The password is invalid or the user does not have a password.': 'Das Passwort ist ungültig oder der Benutzer hat kein Passwort.',
//   'Choose a new password': 'Wählen Sie ein neues Passwort',
//   'Passwords do not match': 'Passwörter stimmen nicht überein',
//   'Should be at least 8 characters': 'Sollten mindestens 8 Zeichen lang sein',
//   'Please enter a valid email address': 'Bitte gib einen gültigen E-Mail ein',
//   'Email is already in the list': 'Diese E-Mail-Adresse ist bereits Clubmitglied',
//   'Your invitation link has expired': 'Ihr Einladungslink ist abgelaufen',
//   'Subject cannot be empty': 'Der Betreff darf nicht leer sein',
//   'Message cannot be empty': 'Die Nachricht darf nicht leer sein',
//   'Title cannot be empty': 'Titel darf nicht leer sein',
//   'Description cannot be empty': 'Die Beschreibung darf nicht leer sein',
//   'Phone number cannot be empty': 'Die Telefonnummer darf nicht leer sein',
//   "The phone number is not valid, please use the '+4917669423506' format": 'TODO',
//   "Please add the country code to your number in the '+49' format": 'TODO',
//   'This is not a valid number for the region.': 'TODO',
//   'My Bookings': 'Meine Bestellungen',
//   'Previous Bookings': 'Vorherige Bestellungen',
//   'Contact Info': 'Kontakt Info',
//   'There are no bookings': 'Noch keine Bestellungen',
//   'Your order': 'Deine Bestellung',
//   'has been placed.': 'ist bei uns eingegangen.',
//   'Same address for delivery': 'Gleiche Zustelladresse',
//   'you can modify it later if needed': 'Du kannst sie später bei Bedarf ändern',
//   'Your Repair Rebels Cart': 'Dein Repair Rebels Warenkorb',
//   'I have': 'Ich habe',
//   'a proper box to deliver my clothing': 'eine Box, um meine Kleidung zu liefern',
//   'I need': 'Ich brauche',
//   'a delivery box for my clothing': 'eine Versandbox für meine Kleidung',
//   each: 'je',
//   'Since shoes and clothing are going to different destinations, if you have both you’ll need order two different boxes.':
//     'Da Schuhe und Kleidung an verschiedene Zielorte gehen, musst Du in diesem Fall zwei verschiedene Versandboxen bestellen.',
//   'Successfully added to cart': 'Erfolgreich zum Warenkorb hinzugefügt!',
//   Type: 'Typ',
//   Amount: 'Länge',
//   Repair: 'Reparatur',
//   Price: 'Preis',
//   'Add another repair': 'Weitere Reparaturen',
//   'One piece that was waiting in my closet changed...':
//     'Ein Teil, das in meinem Schrank wartete, verwandelte sich in zwei Lieblingsteile, die ich jeden Tag tragen möchte!  Ich bin sehr zufrieden mit dem Ergebnis der Arbeit von Repair Rebel!!  Ich kann es kaum erwarten, ein weiteres Stück zu individualisieren!',
//   'My old leather briefcases look like new...':
//     'Meine alten Lederaktentaschen sehen aus wie neu. Wirklich klasse! Und dazu noch günstiger als eine Neue kaufen. Herzlichen Dank an das Team von Repair Rebels.',
//   'So pleased with the excellent repair of my...':
//     'Ich bin sehr zufrieden mit der ausgezeichneten Reparatur meines Lieblingspullovers aus Alpaka-Wolle, der ein wertvolles Souvenir von meinem Sabbatical in Ecuador. Er hatte große Löcher und jetzt ist nicht einmal mehr nachvollziehbar, wo sie waren.',
//   'Your order cannot be edited within 48 hours of the pickup or delivery date.':
//     'Deine Bestellung kann nicht innerhalb von 48 Stunden bevor dem Abhol- oder Liefertermin bearbeitet werden.',
//   'Please contact us via the': 'Bitte kontaktiere uns über die Seite',
//   'contact us': 'Kontakt',
//   'page to make an alternative arrangement.': 'um eine alternative Vereinbarung zu treffen.',
//   'Please enter a valid zip code for city, or change your location in the User Account area.':
//     'Bitte verwende eine gültige Postleitzahl für city, oder ändere deinen Standort im User Account Bereich.',
//   'Please enter a valid zip code for your city':
//     'Derzeit bieten wir keine Tür zu Tür Lieferung an Deinem Standort an. Bitte gib Deine Bestellung über <0>“Schätzung Anfordern”</0> auf und sende uns Deine Reparaturen per Post!',
//   'First name required': 'Vorname erforderlich',
//   'Last name required': 'Nachname erforderlich',
//   'Address required': 'Adresse erforderlich',
//   'The phone number is not valid, please use a valid format': "Die Telefonnummer ist nicht gültig, bitte verwende das Format: '+4917669423506'",
//   'Back to the Homepage': 'Zurück zur Startseite',
//   "This page doesn't exist.": 'Diese Seite existiert nicht.',
//   'Please accept our terms': 'Bitte akzeptieren Sie unsere AGB',
//   'Discount Code': 'Rabattcode',
//   'discount is activated': 'rabatt wird aktiviert',
//   Discount: 'Rabatt',
//   'Please select delivery time frame': 'Bitte wählen Sie Zustellzeit',
//   'Invalid input': 'Ungültige Eingabe',
//   'Submit Update': 'Update senden',
//   'Delivery Address': 'Zustelladresse',
//   'Thank you': 'Danke',
//   'has been updated': 'wurde aktualisiert',
//   'You will receive an email confirming these changes.': 'Du erhältst in Kürze eine E-Mail, die diese Änderungen bestätigt.',
//   'The details of your order has been updated': 'Die Details Ihrer Bestellung wurden aktualisiert',
//   'Status: Active': 'Status: Aktiv',
//   'Delivery Time': 'Zustellzeit',
//   timeGapNote: 'Bitte beachte, dass zwischen der Abhol- und der Zustellzeit 7 Tage liegen müssen. Möglicherweise musst Du die Zustellzeit zuerst ändern.',
//   'Pick Up Time': 'Abholzeit',
//   Date: 'Datum',
//   'Pick Up Address': 'Adresse abholen',
//   'Email address cannot be empty': 'E-Mail-Adresse darf nicht leer sein',
//   Order: 'Bestellen',
//   'Please select pick up time frame': 'Bitte wählen Sie Abholzeitrahmen',
//   'Please click on the button below and sign up again': 'Bitte klicken Sie auf den Button unten und melden Sie sich erneut an',
//   'Name required': 'Name erforderlich',
//   'Not Allowed': 'Nicht erlaubt',
//   'Your order cannot be edited withing 48 hours of the pickup or delivery date.':
//     'Ihre Bestellung kann nicht innerhalb von 48 Stunden nach dem Abhol- oder Lieferdatum bearbeitet werden.',
//   ...appProductsI18nDe,
//   ...appTailorsI18nDe,
// };

const appI18nDe = {
  'Unexpected error. Try again later or contact us': 'Unerwarteter Fehler. Versuchst Du es später erneut oder kontaktiere uns',
  'Order access error': 'Bestellung nicht gefunden oder Du bist nicht berechtigt, sie zu bearbeiten. Bitte prüfe, ob Du eingeloggt bist oder kontaktiere uns!',
  'Order rating access error':
    'Bestellung nicht gefunden oder Du bist nicht berechtigt, Schneider zu bewerten. Bitte prüfe, ob Du eingeloggt bist oder kontaktiere uns!',
  'Not Found': 'Nicht Gefunden',
  'Go back': 'Geh zurück',
  'Frequently Asked Questions': 'Häufig gestellte Fragen',
  'Our Supporters': 'Unsere Unterstützer',
  'Let us take cate of them!': 'BEHALTE SIE UND LASSE SIE UNS REPARIEREN',
  'Let us take': 'Behalte sie und lasse sie uns reparieren',
  'Keep them and let us take care of them!': 'Behalte sie und lasse sie durch uns reparieren.',
  'Still in love with your favourites?': 'Du hängst an Deinen Lieblingsstücken?',
  'Door to door deliveries': 'Lieferung von Tür zu Tür in',
  'Not in': 'Nicht in',
  Within: 'Innerhalb von',
  'We pick it up and we deliver it back': 'holen wir Deine Lieblingsstücke ab und stellen sie wieder zu',
  'Tuesdays & Fridays': 'Dienstags und freitags',
  'Pick ups and deliveries <1>only in Düsseldorf</1>': 'Abholung und Lieferung <1>nur in DÜSSELDORF</1>',
  'One week only!': 'Nur 7 Tage',
  'Get your garment back within a week': 'Bekomme Deine Lieblingsstücke innerhalb von 7 Tagen zurück',
  'Loading...': 'Laden..',
  'Voucher': 'Gutschein',
  'Get estimation': 'Schätzung anfordern',
  'Get estimation description':
    'Kein passendes Angebot dabei oder hast Du eine besondere Reparatur? Fordere jetzt eine Schätzung an. Wir möchten uns um jedes Lieblingsstück kümmern und Dir ein individuelles Angebot schicken.',
  estimationDataPrivacy:
    'Ich willige ausdrücklich in die Erhebung und Verarbeitung meiner personenbezogenen Daten zur Schätzung der Kosten, welche durch die angefragte Reparaturleistung entstehen, ein. Alle Angaben sind freiwillig. Die Hinweise zum Datenschutz, die unter den <0>Link</0>, abrufbar sind, habe ich zur Kenntnis genommen*',
  ratingDataPrivacy:
    'Ich willige ausdrücklich in die Erhebung, Verarbeitung und Veröffentlichung meiner personenbezogenen Daten zur Bewertung der Leistung ein. Alle Angaben sind freiwillig. Die Hinweise zum Datenschutz, die unter den <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen*',
  contactUsDataPrivacy:
    'Ich willige ausdrücklich in die Erhebung und Verarbeitung meiner personenbezogenen Daten zur Kontaktaufnahme ein. Die Hinweise zum Datenschutz, die unter den <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen.',
  "Let's get started!": 'Los geht’s!',
  'Choose the item to repair': 'Wähle den Artikel aus, für den du eine Reparatur benötigst',
  Dresses: 'Kleider',
  Trousers: 'Hosen',
  Partnerships: 'PARTNERSCHAFTEN',
  'BRAND PARTNERSHIPS': 'MARKEN-PARTNERSCHAFTEN',
  'Brand subtitle': 'SEIEN SIE VORBEREITET: SCHLIEßEN SIE DEN MODE-KREISLAUF MIT UNS!',
  'Brand text': 'Unser preisgekrönter Mode-Kreislauf Service bietet anpassbare und skalierbare End-to-End Lösungen für Ihre Marke. Werden Sie Teil unseres Netzwerks von Modeexpert:innen und Pionier:innen! Erschließen Sie neue Geschäftsmodelle mit unserem digitalen Reparatur-Buchungsportal und Leistungen in Ihren Geschäften vor Ort. Jetzt in ganz Deutschland verfügbar und zunehmend auch in Europa.',
  'Join ecosystem title': 'Werden Sie Teil unseres Netzwerks von Modeexpert:innen und Pionier:innen',
  'Ecosystem text': 'Mit guten Verbindungen zu Ersatzteillieferanten, NROs, führenden akademischen Einrichtungen, Handwerkskammern und dem globalen Startup-Ökosystem, helfen wir Ihnen dabei die Anforderungen der kommenden EU-Ökodesign-Verordnung zu erfüllen und eine profitable Geschäftsstrategie für kreislaufwirtschaftliches Handeln zu entwickeln.',
  'THE REPAIR REBELS EXPERTISE': 'REPAIR REBELS KNOW-HOW',
  'Expertis text': 'Repair Rebels hat es sich zur Aufgabe gemacht, Textil- und Schuhreparaturen digital, bequem und modern zu machen! Um dies zu erreichen, bauen wir eine einzigartige Handwerker-Infrastruktur auf und spezialisieren uns auf die Optimierung von Prozessen sowie Software für die Rückwärtslogistik. Von Textilien bis hin zu Schuhen, Taschen und Schmuck bieten wir hochwertige Dienstleistungen und eine nahtlose digitale Kundenerfahrung entlang der gesamten Wertschöpfungskette.',
  'You Are': 'SIE SIND',
  'Sustainably driven fashion brand': "Nachhaltig orientierte Modemarke",
  'Fashion store or online shop': 'Modegeschäft oder Online-Shop',
  'Vintage or second-hand shop': 'Vintage- oder Second-Hand-Laden',
  'Fashion care & repair product supplier': 'Anbieter von Modepflege- und Reparaturprodukten ',
  'REQUEST A PERSONAL CONSULTATION': 'PERSÖNLICHE BERATUNG ANFRAGEN',
  '...BECAUSE THERE IS NO CIRCULAR FASHION WITHOUT CARE & REPAIR!': '...DENN OHNE CARE & REPAIR LÄSST SICH DER MODE-KREISLAUF NICHT SCHLIEßEN!',
  'EXPLORE NEW BUSINESS OPPORTUNITIES': 'ERSCHLIESSUNG NEUER GESCHÄFTSMODELLE',
  'TRANSITION TO CIRCULAR ECONOMY': 'ÜBERGANG ZUR KREISLAUFWIRTSCHAFT',
  'SET INDUSTRY STANDARDS': 'INDUSTRIESTANDARDS SETZEN',
  'EMBRACE COLLABORATIVE INNOVATION': 'FÖRDERUNG KOLLABORATIVER INNOVATIONEN',
  'SUPPORT GERMAN CRAFTSMANSHIP': "DEUTSCHE HANDWERKSKUNST UNTERSTÜTZEN",
  ErrorCustomClubMessage: 'DIESE E-MAIL WURDE BEREITS VERWENDET',
  Tops: 'Oberteile',
  MainModalTitle: 'JOIN THE REPAIR REVOLUTION!',
  'Modal Content Top': 'Für praktische Tipps und exklusive Angebote, registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
  'Modal Content Bottom': 'Und erhalten <0>5% Rabattcode</0> für Deine erste Reparaturbuchung bei uns!',
  'Modal Mobile Content Bottom': 'Registriere Dich jetzt für unsere Newsletter und erhalten <0>5% Rabattcode</0> für Deine erste Reparaturbuchung bei uns!',
  'Modal Check Box Text': 'Die Hinweise zum Datenschutz, die unter dem <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen.',
  'YOUR INVITATION HAS BEEN SENT!': 'DEINE EINLADUNG IST VERSCHICKT!',
  'Check your inbox for the invitation email': 'Danke Prüfe Deinen Posteingang auf die Einladungs-E-Mail',
  'Registrieren': 'REGISTRIEREN',
  'BOOK A REPAIR': 'REPARATUR BUCHEN',
  Jackets: 'Jacken',
  PriceGuide: 'Preise',
  BookRepair: 'REPARATUR BUCHEN',
  WhyRepairRebels: 'How it works Website?',
  ImageProductUploadText: 'Bitte lade ein paar Bilder oder Videos hoch, um uns dabei zu helfen, den am besten geeigneten Handwerkskünstler:in  zu finden.',
  GetAnEstimate: 'SCHÄTZUNG ANFRAGEN',
  Sneakers: 'Sneaker',
  Bags: 'Taschen',
  '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>': '<0><0>Gut für die Umwelt</0><1></1></0><1>& gut für deinen Geldbeutel!</1>',
  'Collect CO2 Credits and get discounts': 'Sammle CO2 Credits und erhalte Rabatte.',
  'Learn More': 'erfahre mehr',
  'Try it now!': 'Los geht´s!',
  '<0>Want to become a</0><1>Repair Rebel?</1>': '<0>Werde ein</0><1>Repair Rebel!</1>',
  'Why <1></1> Repair Rebels?': 'Warum <1></1> Repair Rebels?',
  ChooseProduct: '1. PRODUKT AUSWÄHLEN',
  'Description About Product': 'Jedes Modestück kann repariert werden! Wähle die Produktart, die eine Reparatur oder etwas mehr Liebe benötigt.',
  ChooseRepair: '2. REPARATUR WÄHLEN',
  'Description About Repair': "Wähle aus einer Liste von Reparatur-, Änderungs- oder Restaurierungsdienstleistungen. Wenn Du nichts passendes findest, schicke uns eine Anfrage über 'Schätzung anfordern'.",
  ChooseDelivery: '3. LIEFERUNG WÄHLEN',
  'Description About Delivery': 'Wir bieten Dir einen sicheren deutschlandweiten Versand oder eine persönliche Tür-zu-Tür-Lieferung innerhalb von Düsseldorf und Köln.',
  BecomeRebel: '4. REBELL WERDEN',
  'Description About Rebel': 'Erstelle ein Benutzerkonto und trete dem Super Rebel Loyalty Club bei. Verfolge Deine Bestellungen und genieße Deine besonderen Vorteile.',
  ReceiveOrder: '5. BESTELLUNG ERHALTEN',
  'Description About Order': 'Bekomme Deine reparierten Lieblingsstücke innerhalb von 7-14 Tagen zurück. Wiederhole den Prozess mit weiteren Modestücken & teile Deine Erfahrungen mit Freunden!',
  homePageLoyaltyClubBody:
    'Erhalte praktische Tipps, exklusive Angebote und sammle CO2 Credits. Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
  homePageLoyaltyClubDescriptiveParagraphHeader: 'Das erwartet Dich: ',
  homePageLoyaltyClubDescriptiveParagraph:
    'Viele nützliche Tipps und Tricks wie Du Deine geliebten Kleidungsstücke, Schuhe und Taschen länger nutzen kannst. Coole Facts und Infos zum Thema Mode, Nachhaltigkeit und Events in Deiner Nähe. Und wie sollte es anders sein, natürlich wirst Du für Deine Treue und Leidenschaft für Reparaturen auch mit Rabatten und Treuepunkten belohnt. Sei dabei und verpasse keine Neuigkeiten mehr!',
  homePageLoyaltyClubCallToAction: 'Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
  homePageLoyaltyClubQuote: 'Du möchtest ein echter Repair Rebel werden? Dann werde Teil des Super Rebel Loyalty Clubs.',
  'Check your inbox for the invite email': 'Prüfe deinen Posteingang auf die Einladungs-E-Mail',
  'Invite sent': 'Einladung verschickt',
  'You are subscribed': 'Hooray! Du hast Dich erfolgreich für den Newsletter des Super Rebel Loyalty Clubs angemeldet.',
  'Discounts & Packages': 'Rabatte & Gutscheine',
  'New Loyaolty Text': 'Erhalte praktische Tipps, exklusive Angebote und sammle CO2 Credits. Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
  'Join the club': 'Trete dem Repair Rebel Loyalty Club bei',
  'Check Box Loyalty Personal Data': 'Ich willige ausdrücklich in die Erhebung und Verarbeitung meiner personenbezogenen Daten ein. Die Hinweise zum Datenschutz, die unter dem <0>Link</0> abrufbar sind, habe ich zur Kenntnis genommen.',
  Back: 'Zurück',
  'Our Rebels': 'Das sagen unsere Kunden',
  'See More': 'Mehr',
  'Our Tailors': 'Unsere Partner vor Ort',
  'Meet our local partners': 'Lerne unsere Partner und ihre Geschichten kennen',
  homePageBlogIntro:
    'Unsere lokalen Partner unterstützen unsere Mission, Deinen Kleidungsstücken ein Update zu geben, sodass Du sie länger tragen kannst. Unsere ausgewählten SchneiderInnen und Schuster sind Meister ihres Faches und zeigen Leidenschaft und Liebe bei jeder Reparatur.',
  'Contact Information': 'Kontaktinformation:',
  'Contact Information - Bodytext 1':
    'Wir sind auf der Mission, Textil- und Schuhreparaturen bequem, sozial und modisch zu gestalten! Für die Umwelt, stilvolle Rebellen und für lokale Talente.',
  'Contact Information - Contact Us': 'Kontaktiere uns für weitere Informationen:',
  'Contact Information Estimation': 'Kontaktdaten',
  Imprint: 'Impressum',
  'Privacy Policy': 'Datenschutz',
  'Terms and Conditions': 'AGB',
  // Detailed image button
  from: 'ab',
  'Main services': 'Dienste',
  Address: 'Adresse',
  'Name for the Address': 'Namen für die Adresse',
  'Address line': 'Adresszeile',
  'Pictures of his work': 'Bilder seiner Arbeit',
  'Pictures of her work': 'Bilder ihrer Arbeit',
  'Good for the planet': 'Gut für die <br /> Umwelt',
  'Good for your wallet': 'Gut für deinen <br /> Geldbeutel',
  'Good for the local economy': 'Gut für die lokale <br /> Wirtschaft',
  'CO2 Credits Per Garment': 'CO<sub>2</sub> Credits',
  BackToOrder: 'ZURÜCK',
  DownloadReceipt: 'BELEG HERUNTERLADEN',
  ShipByPost: 'Ich möchte meine Reparaturen per Post verschicken',
  BookingDoorToDoor: 'Ich möchte die Lieferung von Tür-zu-Tür buchen',
  OrderSummary: 'ORDER SUMMARY',
  FooterContent: 'REPAIR REBELS',
  'B2B Partnerships': 'B2B-Partnerschaften',
  TransportationInfo: 'DEINE VERSANDADRESSE',
  Packaging: 'Verpackung',
  YourBrand: 'DEINE MARKE',
  'Collaborate with brands': 'Wir freuen uns auf die Zusammenarbeit mit Marken und Einzelhändlern, die unsere Leidenschaft für nachhaltige Mode teilen.',
  GetInTouch: 'KONTAKTIEREN',
  CorporatePartnerships: 'PARTNERSCHAFTEN MIT UNTERNEHMEN',
  MoreInfo: '+ Mehr Info',
  outside_information: 'Wenn Du Dich außerhalb von Deutschland befindest, kontaktiere uns über das Formular für <0>Kostenvoranschläge</0>',
  Transportation: 'Lieferung & Versand',
  'Fees include two-way transportation': 'Gebühren beinhalten den Hin- und Rücktransport',
  'Transportation Modal - Information 1':
  'Wenn Du Dich für den Postversand entscheidest, senden wir Dir zwei Versandetiketten zu, Du verpackst Deine Reparaturen in einen Karton und bringst diese zu einer Deutschen Post Filiale. Nach Abschluss der Reparaturarbeiten schicken unsere Handwerkskünstler sie Dir zurück.',
  'Transportation Modal - Information 2':
  'Wenn Du Dich für die Tür-zu-Tür Lieferung entscheidest, holt ein Repair Rebels Mitarbeiter die Reparaturen bei Dir zu Hause oder im Büro ab und bringt sie Dir anschließend zurück.',
  'Ship post price': '12.50 €',
  'Door to door price': '14.50 €',
  Garment: 'Kleidungsstück',
  'CO2 Credits': 'CO<sub>2</sub> Credits',
  'Why CO2 Credits?': 'Wozu CO<sub>2</sub> Credits?',
  CO2_page_body:
    '<p>Unsere Mission ist es, deine Garderobe nachhaltiger zu gestalten. Wenn du deine Lieblingsstücke länger behältst und pflegst, sparst du Zeit, Geld und gleichzeitig jede Menge CO2 und natürliche Ressourcen.</p><p>Mit den CO2 Credits kannst du sehen, wie viel CO2 du mit der Reparatur im Vergleich zum Neukauf eingespart hast. Mit den gesammelten Credits aktivierst du außerdem auch Rabatte und Gutscheine. Also los geht‘s!</p>',
  'Start Repairing': 'Jetzt Reparieren!',
  'START REPAIRING': 'REPARIEREN',
  'PRESS PACKAGE': 'PRESSEPAKET',
  'PRESS TEXT': 'MÖCHTEST DU MEHR ÜBER UNSERE ARBEIT ERFAHREN? SCHAUE UNSER PRESSEPAKET AN, IN DEM DU UNSERE PRESSEMITTEILUNGEN UND FOTOS FINDEST',
  'Join the Club': 'Ich möchte dem Super Rebel Loyalty Club beitreten!',
  'How It Works': 'FAQs',
  'CRAFTSMEN': 'HANDWERK',
  'About Us': 'Über Uns',
  'Tips & More': 'Tipps & Mehr',
  Tailors: 'Unsere Partner',
  Contact: 'Kontakt',
  Repairing: 'Reparieren',
  //"Choose the item to repair": "Wähle den zu reparierenden Artikel",  // DUPLICATE; TODO: choose correct translation
  'Select the item type': 'Wähle den Artikeltyp',
  'Select the type of repair': 'Wähle die gewünschte Reparatur',
  'Select the type of service': 'Wähle die gewünschte Dienstleistung',
  'Further Details': 'Weitere Details',
  'Send us your request': 'Schicke uns Deine Anfrage',
  'Add to Cart': 'In den Warenkorb',
  Adding: 'Warte', // NOT SURE ABOUT THIS
  Comments: 'Kommentare',
  'Type of Repair': 'Reparatur',
  'Item Type': 'Artikel',
  product_estimation_hint:
    'Kein passendes Angebot dabei oder hast Du eine besondere Reparatur? Fordere jetzt eine Schätzung an. Von Omas Mantel bis hin zu Deinem Camping Zelt, wir schauen uns jede Anfrage individuell an und suchen einen passenden Repairkünstler aus.',
  'Garment Description': 'Beschreibung des Kleidungsstücks',
  'Describe your requests for repair': 'Beschreibe Deine Reparatur oder Änderungs-Wünsche',
  Images: 'BILDER & VIDEOS',
  'Images are really important for this process':
    'Hochwertige Bilder und Videos sind für unser Team sehr wichtig, um Deine Anfrage genau zu bewerten und den am besten geeigneten Reparaturkünstler zuzuweisen. Bitte stelle sicher, dass Du detaillierte Informationen über den beschädigten Bereich sowie den gesamten Artikel bereitstellst.',
  estimation_request_form_image_hint: 'Hochwertige Bilder und Videos sind für unser Team sehr wichtig, um Deine Anfrage genau zu bewerten und den am besten geeigneten Reparaturkünstler zuzuweisen. Bitte stelle sicher, dass Du detaillierte Informationen über den beschädigten Bereich sowie den gesamten Artikel bereitstellst.',
  'Upload Image': 'Dateien anhängen',
  'ATTACHED FILES': 'DATEIEN ANGEHÄNGT',
  'Send Request': 'Abschicken',
  'Your request for a personal offer has been sent': 'Deine Anfrage für ein persönliches Angebot wurde verschickt',
  'Together with our tailors and shoemakers':
    'Wir schauen nun gemeinsam mit unseren Schneidern und Schuster, was wir für Dein Lieblingsstück tun können und melden uns bald bei Dir',
  'Book further repair': 'Weitere Reparatur buchen',
  'Back to homepage': 'Zurück zur Startseite',
  // from here I stop adding obvious English keys, thus relying on them as on default ones
  Description: 'Beschreibung',
  Title: 'Titel',
  sizeError: 'Die Upload-Dateigröße ist größer als 50 MB. Verwenden Sie ein anderes Bild',
  cityDeliveryError: 'Wähle eine Stadt für die Tür-zu-Tür Lieferung aus',
  Name: 'Name',
  Email: 'E-Mail',
  Gallery: 'Gallerie',
  'Contact Us': 'Kontakt',
  Subject: 'Betreff',
  'Your phone number': 'Deine Telefonnummer ( +4917669423504 )',
  'Send Message': 'Senden',
  'Thank you Repair Rebel': 'Vielen Dank<0/> Repair Rebel!',
  'Go back to home': 'Zurück zur Startseite',
  contact_form_hint: 'Einer unserer Agenten wird sich so schnell wie möglich bei Ihnen melden.',
  contact_form_banner_text: 'Ihre Anfrage wurde gesendet.',
  order_status_view_hint: 'Du erhältst in Kürze eine E-Mail-Bestätigung mit weiteren Details und Anweisungen.',
  'Your name': 'Dein Name',
  'Your email address': 'Deine E-Mail-Adresse',
  'Your message': 'Deine Nachricht',
  'Send us an email': 'Sende uns eine E-Mail',
  Login: 'Login',
  Register: 'Jetzt Registrieren',
  "Don't have an account yet?": 'Du hast noch keinen Account?',
  "Don't have any account yet?": "DU HAST NOCH KEINEN ACCOUNT?",
  'Easily track your orders and store receipts': 'Verfolge Deine Bestellungen und speichere Belege',
  'Receive exclusive offers and discounts': 'Erhalte exklusive Angebote und Rabatte',
  'Continue as a guest': 'Als Gast fortfahren',
  Password: 'Passwort',
  'Forgot your password?': 'PASSWORT VERGESSEN?',
  'Create password': 'Passwort erstellen',
  'Register now': 'Registriere dich jetzt',
  'Forgotten Password': 'Passwort vergessen?',
  Send: 'Absenden',
  auth_password_reset_hint:
    'Gib die E-Mail-Adresse ein, mit der du dein Repair Rebel Konto erstellt hast. Wir schicken dir an diese Adresse einen Link zum Zurücksetzen deines Passwortes.',
  Guest: 'REBEL!',
  'First Name': 'Vorname',
  'Last Name': 'Nachname',
  'Phone Number': 'Telefonnummer',
  Location: 'Ort',
  Country: 'Land',
  Subscribe: 'ABONNIEREN',
  City: 'Stadt',
  StreetAndHouse: 'Straße und Hausnummer',
  Street: 'Straße',
  'Zip code': 'Zip code',
  'Confirm Password': 'Bestätige dein Passwort',
  auth_register_submit_label: 'Jetzt Registrieren',
  auth_register_login_heading: 'Du hast bereits ein Kundenkonto?',
  auth_register_heading: 'Registriere dich',
  auth_register_join_club_label: 'Ich möchte dem Repair Rebel Loyalty Club beitreten',
  auth_register_join_club_sublabel: 'Sammle CO2 Credits und erhalte Rabatte und Gutscheine',
  auth_register_accept_terms_label:
    'Hiermit erkläre ich mich mit den <0>Datenschutzbestimmungen</0>, <1>AGB und Widerrufsbestimmungen</1> von Repair Rebels einverstanden*',
  fields_with_asterisks: 'Die mit einem (*) markierten Felder sind Pflichtfelder.',
  newsletter_clarification:
    '¹ Du kannst dich jederzeit wieder vom Newsletter abmelden. Mit Setzen des Hakens erkläre ich mich einverstanden, dass die von mir erhobenen Daten für die Bearbeitung meiner Anfrage elektronisch erhoben und gespeichert werden. Diese Einwilligung kann jederzeit mit einer Nachricht an uns widerrufen werden. Weitere Informationen kannst du der Datenschutzerklärung entnehmen.',
  auth_forgot_password_success_text:
    'Ein Link zum Zurücksetzen des Passworts wurde gesendet. Um Ihr Passwort zurückzusetzen, öffnen Sie bitte den Link und folgen Sie den Anweisungen.',
  'Change Password': 'Passwort ändern',
  'Current Password': 'Jetziges Passwort',
  'New Password': 'Neues Passwort',
  Change: 'Aktualisieren',
  Code: 'Kode',
  Jobs: 'Arbeitsplätze',
  'Become a repair rebel': 'Werde ein Repair Rebel!',
  'Password has been changed': 'Ihr passwort wurde erfolgreich geändert!',
  Close: 'Schließen',
  Delete: 'Löschen',
  'Are you sure': 'Bist du sicher',
  'Add Address': 'Adresse hinzufügen',
  'My Addresses': 'Meine Adressen',
  'Current addresses': 'Aktuelle adressen',
  Add: 'Hinzufügen',
  'Add New Payment Method': 'Neue Zahlungsmethode hinzufügen',
  'Payment Methods': 'Zahlungsarten',
  'Card Number': 'Kartennummer',
  'Expiry Month': 'Monat ablaufen',
  'Expiry Year': 'Ablaufjahr',
  'Visa endings with': 'Visa endet mit',
  Edit: 'Bearbeiten',
  Save: 'Speichern',
  Confirm: 'Speichern',
  product_further_details_frame_heading: 'Hey Rebel!<br />Toll, dass du eine Reparatur buchen möchtest!',
  product_further_details_frame_hint: 'Wir optimieren grade unser online Buchungssystem. In der Zwischenzeit nehmen wir Bestellungen über WhatsApp an:',
  'Because everyone and everything deserves a second chance': 'Weil jeder und alles eine zweite Chance verdient',

  'How was the tailors performance?': "Wie war der Service von {{tailorName}}'?",
  'How was the tailors unpublish performance?': "Wie war der Service?",
  'How happy are you with the repair?': "WIE HAPPY BIST DU MIT DER {{repairProducts}} REPARATUR?",
  'Your comment': 'Dein Kommentar', // ENGLISH
  'Please rate our service': 'Bitte bewerte unsere Leistung',
  'How were things managed by us?': 'Wie war der Service von Repair Rebels?',
  'Rate your tailor(s)/cobbler(s)': 'Bitte bewerte Deinen Schneider/Schuster',

  'There are no addresses': 'Es sind keine Adressen vorhanden',
  'Hey there, super rebel': 'Hey Du, Super Rebel!',
  'You are part of our loyalty club': 'Du bist Teil unseres Treueclubs',
  'Your CO2 points balance': 'Deine CO2-Punktebilanz:',
  'Check your benefits': 'Überprüfe Deine Vorteile',
  'Join our Rebel team!': 'Werde Teil unseres Rebellen-Teams!',
  'Add another product': 'Weitere Artikel reparieren',
  'Add another voucher': 'Weitere Artikel voucher',
  'Go to cart': 'Zum Warenkorb',
  'Proceed to payment': 'WEITER ZUR ZAHLUNG',
  'Courier Service': 'Kurier Service (ab 145€ Bestellwert kostenlos)',
  'Packaging Cost': 'Verpackungskosten',
  'Final Price': 'Total',
  'Product Cost': 'Produktkosten',
  'Shipping': 'Lieferung',
  inclVAT: 'inkl. MwSt.',
  'Order as a Guest': 'Als Gast bestellen',
  Or: 'Oder',
  rating_success_heading: 'Hurray, Repair Rebel!',
  rating_success_hint: 'Wir schätzen Deine Meinung wirklich sehr und wir arbeiten daran, jeden Tag besser zu werden!',
  'The idea of Repair Rebels was born from':
    'Die Idee für Repair Rebels ist aus der Liebe zur Mode und der Überzeugung, dass unsere Kleidungsstücke mehr Wertschätzung verdienen, entstanden. Der wahre Wert unserer Kleidung wird nicht im Geschäft entschieden - wir geben ihr den Wert, indem wir sie lieben, tragen, in ihr leben und uns um sie kümmern. Daher bieten wir Dir eine einfache und moderne Lösung, um Deine Kleidung und Schuhe zu reparieren.',
  "That's why we want to rebel the fashion system":
    'Deshalb möchten wir das Modesystem rebellieren und Deine Lieblingsstücke nicht ersetzen, sondern reparieren. Dazu trifft auf unserer Plattform Technologie auf traditionelles Handwerk. Wir wollen die Welt zu einem besseren Ort machen, indem wir die schnelllebige Modeindustrie und den Konsumerismus bremsen. Beinahe die Hälfte der Deutschen hat noch nie ein Kleidungsstück oder ein paar Schuhe reparieren lassen (Greenpeace, 2022). Stattdessen bestellen die meisten Konsumenten online neue Kleidung und ersetzen so ihre Lieblingsstücke. Wir wollen die Wertschätzung für die Handarbeit und den Respekt für Dinge, die wir bereits besitzen, fördern und das lokale Handwerk unterstützen.',
  'Repairing is an attitude':
    'Reparieren ist eine Haltung - eine Lebensphilosophie. Wenn wir unsere Kleidung reparieren können, können alles im Leben reparieren',
  'Fast fashion was yesterday':
    'Fast Fashion war gestern, Slow Fashion und damit auch Reparaturen sind die Zukunft. Reparaturen unserer Lieblingsstücke sparen uns nicht nur viel Zeit und CO2 Emissionen, sondern sie sind auch günstiger und schaffen dabei lokale Arbeitsplätze. Indem Du Deine Sneaker, Jeans, Lederjacke oder auch jedes andere Lieblingsstück länger behältst, sammelt ihr gemeinsam Erinnerungen und Du kannst ein Style-Statement setzen. Wir machen Textil- und Schuhreparaturen so modisch und einfach wie den Kauf neuer Artikel - nur günstiger, nachhaltiger und lokaler. Reparieren war noch nie so digital und so sexy. So kannst Du bequem vom Sofa aus eine Reparatur buchen. Das Kleidungsstück wird zum gewünschten Zeitpunkt bei Dir abgeholt und einige Tage später wieder repariert zugestellt. Wir bieten einen digitalen Rundumservice inklusive Abholung und Zustellung. So wird Repair Rebels zu einer echten Alternative zum Neukauf!',
  "Sounds good, doesn't it? Show love & repair your favorites!": 'Klingt gut, oder? Show love & repair your favourites!',
  'We should rethink our relationship...':
    'Wir sollten unsere Beziehung zu unserer Kleidung neu denken und sie wie eine zweite Haut behandeln und sie pflegen.',
  'Repair now!': 'Jetzt reparieren!',
  'I want to join the super rebel loyalty club!': 'Ich möchte dem super rebel loyalty club beitreten!',
  'The idea of Repair Rebels was born out of a love for fashion...':
    'Die Idee für Repair Rebels ist aus der Liebe zur Mode und der Überzeugung, dass unsere Kleidungsstücke mehr Wertschätzung verdienen, entstanden. Wir machen unsere Erfahrungen und Erlebnisse in unserer Kleidung, so können mit einem Kleidungsstück zahlreiche Erinnerungen verbunden werden. Das macht es um so wichtiger, unsere Kleidung von einem Local Hero retten zu lassen. So geben wir jedem Kleidungsstück eine zweite Chance uns bei vielen weiteren glücklichen Momenten zu begleiten.',
  "But of course, we don't accomplish all of this alone...":
    'Aber all das erreichen wir natürlich nicht alleine! Wenn Du Teil unserer Mission werden möchtest, bist Du hier genau richtig!',
  'Are you looking for a dynamic and impact-driven company...':
    'Du suchst ein dynamisches und Impact-Driven Unternehmen, das die Modebranche revolutionieren möchte und sich stetig weiterentwickelt? Dann bist Du hier genau richtig! Als Teil unseres internationalen und ambitionierten Teams arbeitest Du an einer Pionierlösung, um die lokalen Handwerksindustrie zu digitalisieren und gestaltest aktiv die Zukunft der Modeindustrie.',
  'Not in Düsseldorf?': 'Nicht in Düsseldorf?',
  'Book your repairs via "Estimate Request" and send it to us via post': 'Dann buche über "Schätzung anfordern" und verschicke Dein Paket per Post',
  'Select the item for which you need a repair.': 'Wähle den Artikel aus, für den Du eine Reparatur oder Änderung benötigst.',
  'Pick-up and delivery is free of charge for orders over 125€.': 'Lieferung und Versand sind ab einem Bestellwert von 145€ kostenlos!',
  'send us your question(s)': 'Stell uns deine Frage(n)',
  "Send us a WhatsApp message and we'll see what we can do for you!": 'Schicke uns eine WhatsApp Nachricht und wir schauen, was wir für Dich tun können!',
  'Please give us your pick up and delivery information for this booking': 'Bitte geben Sie uns Ihre Abhol- und Lieferinformationen für diese Buchung',
  Dusseldorf: 'Düsseldorf',
  Cologne: 'Köln',
  Essen: 'Essen',
  'Choose your location': 'Wählen Sie Ihren Standort',
  'Invalid zip code': 'Ungültige Postleitzahl',
  Unauthorized: 'Unerlaubter',
  'The address id is not defined': 'Die Adress-ID ist nicht definiert',
  'Internal Server Error': 'Interner Serverfehler',
  'A customer document with the provided id does not exists': 'Ein Kundendokument mit der angegebenen ID existiert nicht',
  'Oops, something went wrong': 'Uups, etwas ist schief gelaufen',
  'Something went wrong': 'Etwas ist schief gelaufen',
  'Please, reload the page to fix it': 'Bitte laden Sie die Seite neu, um sie zu beheben',
  Reload: 'Neu laden',
  Success: 'Erfolg',
  'Sign Out': 'Ausloggen',
  Profile: 'Profil',
  'Invalid email': 'Ungültige E-Mail',
  'Update Password': 'Passwort Aktualisieren',
  'Delete Account': 'Konto löschen',
  removeUserAccountDetails: 'Sie werden Ihr Konto vollständig entfernen. Aktivieren Sie das Kontrollkästchen, um Ihre Aktionen zu bestätigen.',
  'Your account has been successfully deleted': 'Ihr Konto wurde erfolgreich gelöscht. Sie werden weitergeleitet.',
  'The user account has been deleted. Use another email to sign up or contact our support team.':
    'Das Benutzerkonto wurde gelöscht. Verwenden Sie eine andere E-Mail-Adresse, um sich anzumelden, oder wenden Sie sich an unser Support-Team.',
  'The user account has been disabled by an administrator.':
    'Das Benutzerkonto wurde gelöscht. Verwenden Sie eine andere E-Mail-Adresse, um sich anzumelden, oder wenden Sie sich an unser Support-Team.',
  'There is no user record corresponding to this identifier. The user may have been deleted.':
    'Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer wurde möglicherweise gelöscht.',
  'The password is invalid or the user does not have a password.': 'Das Passwort ist ungültig oder der Benutzer hat kein Passwort.',
  'Choose a new password': 'Wählen Sie ein neues Passwort',
  'Your account has been blocked': 'Ihr Konto wurde gesperrt. Überprüfen Sie Ihre E-Mails auf eine Bestätigungsnachricht oder kontaktieren Sie unser Support-Team.',
  'Passwords do not match': 'Passwörter stimmen nicht überein',
  'Should be at least 8 characters': 'Sollten mindestens 8 Zeichen lang sein',
  'Please enter a valid email address': 'Bitte gib einen gültigen E-Mail ein',
  'Email is already in the list': 'Diese E-Mail-Adresse ist bereits Clubmitglied',
  'Your invitation link has expired': 'Ihr Einladungslink ist abgelaufen',
  'Subject cannot be empty': 'Der Betreff darf nicht leer sein',
  'Message cannot be empty': 'Die Nachricht darf nicht leer sein',
  'Title cannot be empty': 'Titel darf nicht leer sein',
  'Description cannot be empty': 'Die Beschreibung darf nicht leer sein',
  'Phone number cannot be empty': 'Die Telefonnummer darf nicht leer sein',
  "The phone number is not valid, please use the '+4917669423506' format": 'TODO',
  "Please add the country code to your number in the '+49' format": 'TODO',
  'This is not a valid number for the region.': 'TODO',
  'My Bookings': 'Meine Bestellungen',
  'Previous Bookings': 'Vorherige Bestellungen',
  'My Receipts': 'MEINE BELEGE',
  'Contact Info': 'Kontakt Info',
  'There are no bookings': 'Noch keine Bestellungen',
  'Your order': 'Deine Bestellung',
  'has been placed.': 'ist bei uns eingegangen.',
  'Same address for delivery': 'Gleiche Zustelladresse',
  'you can modify it later if needed': 'Du kannst sie später bei Bedarf ändern',
  'Your Repair Rebels Cart': 'Dein Repair Rebels Warenkorb',
  'I have': 'Ich habe',
  'a proper bag to deliver my repairs': 'eine Tüte für die Lieferung meiner Reparaturen',
  'I need': 'Ich brauche',
  'a delivery bag for my repairs': 'benötige eine Tüte für die Lieferung meiner Reparaturen',
  each: 'je',
  'Since shoes and clothing are going to different destinations, if you have both you’ll need order two different boxes.':
    'Schuhe und Kleidung gehen an unterschiedliche Empfänger. Wenn Du Reparaturen für beides gebucht hast, musst Du zwei verschiedene Tüten bestellen.',
  'Successfully added to cart': 'Erfolgreich zum Warenkorb hinzugefügt!',
  Type: 'Typ',
  Amount: 'Betrag',
  CategoryVoucher: 'KATEGORIE: GUTSCHEINE',
  TailorClubTitle: 'HANDWERKER GEMEINSCHAFT',
  'Successful subscribed to craftsmen newsletter': 'Du hast Dich erfolgreich für unseren Handwerks-Newsletter angemeldet – freu Dich auf Neuigkeiten und spannende Updates!',
  'Successful subscribed to parntersihp newsletter': 'Sie haben sich erfolgreich für den Partner-Newsletter angemeldet!',
  'JOIN THE NETWORK': 'NETZWERK BEITRETEN',
  'PARTNERSHIPS COMMUINITY': 'PARTNERSHIPS COMMUINITY',
  ForCraftsmen: 'Für das Handwerk',
  Craftsmen: 'HANDWERK',
  RepairHeroes: 'Repair Heroes',
  CraftsmenCooperations: 'Handwerker-Kooperationen',
  'Join Network': 'Werde Teil unseres Handwerker-Netzwerks',
  'Craftsmen Page Main text p1': 'Bei Repair Rebels stehen talentierte und motivierte Handwerker:innen im Mittelpunkt unserer Mission. Wir fördern lokales Handwerk und Nachhaltigkeit, indem wir spannende Projekte anbieten, die zu ihren Fähigkeiten und Verfügbarkeiten passen. Unser Fokus liegt auf einem Arbeitsumfeld, in dem Handwerker:innen wachsen und sich entfalten können.',
  'Craftsmen Page Main text p2': 'Sind Sie ein Profi in Ihrem Handwerk und möchten gemeinsam mit anderen Top-Handwerker:innen gegen Fast Fashion kämpfen? Dann sind Sie bei uns genau richtig! Gestalten Sie die Zukunft der Mode mit und werden Sie Teil einer Gemeinschaft, die sich hochwertigen Reparaturen verschrieben hat.',
  'PageQuote': '“Die Idee ist wirklich prima - man erreicht mit dem digitalen Service Leute, die sonst nichet zu den normalen Öffnungszeiten kommen können, und kann auf diese Weise neue Kundengruppen an sich binden.”',
  'QuoteSignature': 'Karina Ranft, Repair Rebels-Schuhmacherin, im Interview mit der HWK Düsseldorf',
  'RunningLineItem-1': 'WIR SUCHEN SCHUSTER ',
  'RunningLineItem-2': 'SCHNEIDER',
  'RunningLineItem-3': 'GOLDSCHMIEDE',
  'RunningLineItem-4': 'UPCYCLING-DESIGNER',
  'RunningLineItem-5': 'MÖBELRESTAURATOREN',
  'RunningLineItem-6': 'SATTLER',
  'GET IN TOUCH': 'KONTAKT',
  'HOW IT WORKS': "So funktioniert's",
  'RunningLineItem-7': 'WORKSHOP-VERANSTALTER',
  'How It Works p-1': 'Unser Online-Service ermöglicht den deutschlandweiten Versand von Reparaturen, sodass Nutzer bequem von zu Hause aus buchen können. Mit Repair Rebels wird das Reparieren von Kleidungsstücken zur echten Alternative zum Neukauf.',
  'How It Works p-2': 'Fast Fashion ist passé! Slow Fashion und Reparaturen sind die Zukunft. Sie sparen Zeit, reduzieren CO2-Emissionen und schaffen lokale Arbeitsplätze.',
  'How It Works p-3': 'Dank des Europäischen Green Deals und der EcoDesign-Vorschriften entwickeln sich die Rahmenbedingungen ständig weiter. Daher wollen wir ein Netzwerk der besten Handwerkskünstler aufbauen!',
  'Did you know the repair market is expecting a 4,3% growth by 2030?': 'WUSSTEN SIE, DASS DER REPARATURMARKT BIS 2030 MIT EINEM JÄHRLICHEN WACHSTUM VON 4,3 % RECHNET? ',
  'Stay up-to-date with the latest news in the repair industry subscribe now to our newsletter, with no strings attached!': 'Bleiben Sie auf dem Laufenden über die neuesten Entwicklungen in der Mode-Reparaturbranche: Abonnieren Sie jetzt unseren Newsletter, ganz ohne Verpflichtungen!',
  'You are p-1': 'Ein Schneider, Schuster, Goldschmied oder Upcycling-Künstler mit umfassender Erfahrung in Reparaturen.',
  'You are p-2': 'Besitzer eines eigenen Studios oder Geschäfts in Deutschland, ausgestattet mit der entsprechenden mechanischen Ausrüstung.',
  'You are p-3': 'Motiviert, Teil einer branchenverändernden Gemeinschaft zu werden und ihr Geschäft digitaler und effizienter zu gestalten.',
  'You are p-4': 'Leidenschaftlich in ihrem Handwerk und möchten es teilen; der Umgang mit Menschen fällt ihnen leicht.',
  'ABOUT REPAIR REBELS': 'REPAIR REBELS KNOW-HOW',
  'MEET OUR REPAIR HEROS': 'UNSERE REPAIR HEROES',
  'You have successfully subscribed!': 'Sie haben sich erfolgreich angemeldet!',
  'You have successfully subscribed b2b!': 'Sie haben sich erfolgreich angemeldet!',
  "Craftsmen Cooperations - Become part of the Repair Rebels network": 'Handwerker-Kooperationen – Werde Teil des Repair Rebels Netzwerks',
  'Meta Craftsmen Description': ' Entdecken Sie spannende Projekte mit Repair Rebels! Werden Sie Teil des Netzwerks der besten Handwerker Deutschlands, fördern Sie Nachhaltigkeit und hochwertiges Handwerk. Erweitern Sie Ihren Kundenstamm, sichern Sie sich neue Aufträge und wachsen Sie in einer starken Gemeinschaft. Bewerben Sie sich jetzt!',
  'Mission of Repair Rebels': 'Repair Rebels macht Textil- und Schuhreparaturen digital, bequem und modern! Wir bauen eine einzigartige Handwerker-Infrastruktur auf und optimieren Prozesse sowie Software für die Rückwärtslogistik. Von Textilien über Schuhe bis zu Taschen und Schmuck bieten wir hochwertige Dienstleistungen und eine nahtlose digitale Kundenerfahrung entlang der gesamten Wertschöpfungskette.',
  OurRepairHeroes: 'UNSERE REPAIR HEROES',
  'EACH AND EVERY ONE BRINGS EXPERTISE AND PASSION FOR THEIR CRAFT': 'JEDER BRINGT FACHWISSEN UND LEIDENSCHAFT FÜR SEIN HANDWERK MIT',
  'Repair Heroes Subtitle': 'Bei Repair Rebels glauben wir an die Kunst der Reparatur und die Kraft der Gemeinschaft. Möchtest Du auch unserem Netzwerk lokaler Repair Heroes beitreten? Dann erfahre mehr hier!',
  weOfferBrands: 'WIR BIETEN MARKEN UND EINZELHÄNDLERN',
  PricingGuide: 'Preise',
  'Pricing Guide Text': "Liebe Rebels, bitte beachtet, dass die hier angezeigten Preise die Mindestpreise für Standardreparaturen darstellen. Da jede Reparatur einzigartig ist und wir stets ein perfektes Ergebnis anstreben, können die endgültigen Kosten variieren. Falls Ihr kein passendes Angebot gefunden habt oder es sich um eine ganz spezielle Reparatur handelt, füllt bitte das Formular <0>Schätzung anfordern</0> aus.",
  MenuVouchers: 'Gutscheine',
  Length: 'Länge',
  Repair: 'Reparatur',
  Price: 'Preis',
  'Add another repair': 'Weitere Reparaturen',
  'One piece that was waiting in my closet changed...':
    'Ein Teil, das in meinem Schrank wartete, verwandelte sich in zwei Lieblingsteile, die ich jeden Tag tragen möchte!  Ich bin sehr zufrieden mit dem Ergebnis der Arbeit von Repair Rebel!!  Ich kann es kaum erwarten, ein weiteres Stück zu individualisieren!',
  'My old leather briefcases look like new...':
    'Meine alten Lederaktentaschen sehen aus wie neu. Wirklich klasse! Und dazu noch günstiger als eine Neue kaufen. Herzlichen Dank an das Team von Repair Rebels.',
  'So pleased with the excellent repair of my...':
    'Ich bin sehr zufrieden mit der ausgezeichneten Reparatur meines Lieblingspullovers aus Alpaka-Wolle, der ein wertvolles Souvenir von meinem Sabbatical in Ecuador. Er hatte große Löcher und jetzt ist nicht einmal mehr nachvollziehbar, wo sie waren.',
  'Your order cannot be edited within 48 hours of the pickup or delivery date.':
    'Deine Bestellung kann nicht innerhalb von 48 Stunden bevor dem Abhol- oder Liefertermin bearbeitet werden.',
  'I wanted something new and fresh...':'Ich wollte, dass aus meiner beigen Chanel Tasche etwas neues und frisches gezaubert wird, aber ihr habt euch einfach selbst übertroffen! Jetzt ist meine Chanel pink und sieht komplett wie neu aus. Ich bin einfach überwältigt!! DANKE!',
  'I am very excited! Had not thought that...': 'Ich bin sehr begeistert! Hätte nicht gedacht, dass das Ergebnis so gut wird! Ich war bei zwei sehr guten Schuhmachern in Düsseldorf und beide sahen keine Möglichkeit der Reparatur. Werde Euch auf jeden Fall weiterempfehlen. ',
  'My favorite jeans were worn-out in the crotch area...': 'Meine Lieblingsjeans war im Schritt durchgelaufen. Jetzt ist sie durch Repair Rebels wieder wie neu. Großartige Arbeit! Und herrlicher Service. Abholung an der Haustür, Reparatur, Rückbringung innerhalb von 6 Tagen. Ich bin extrem zufrieden und werde 100% Kunde bleiben!',
  'The My experience with Repair Rebels was very...': 'Meine Erfahrung mit Repair Rebels war sehr positiv! Meine Schuhe für die Abschlussfeier brauchten eine Express Reparatur, und dann beschloss ich auch noch, die Farbe meiner Handtasche aufzufrischen und die Sommer Sandalen zu reparieren. Alle 3 Stücke sahen großartig aus, als sie nach wenigen Tagen wieder bei mir zu Hause waren.',
  'The digital ordering process is great and we...': 'Der digitale Bestellprozess ist großartig und wir waren mit dem Service total zufrieden! Vielen Dank für eure tolle Idee! Es ist schön zu wissen, dass wir unsere liebgewonnen Dinge jetzt zur Reparatur in so gute Hände geben können! ',
  'Many thanks to the team of Repair Rebels. My beloved...': 'Ganz herzlichen Dank an das Team von Repair Rebels. Meine geliebte Tasche hatte viele Gebrauchsspuren und ich wusste nicht, wer mir da helfen konnte... bis ich auf Repair Rebels aufmerksam geworden bin. Meine Tasche ist jetzt wie neu, tolles Handwerk, wirklich super geworden!',
  'We are totally thrilled with your service!!!...': 'Wir sind total begeistert von eurem Service!! Ganz herzlichen Dank für alles. Wir werden euch auf jeden Fall weiterempfehlen!',
  'I thought that my hiking boots were lost. But they are...': 'Ein Bild von den verloren geglaubten Wanderschuhen, und nun haben die gut eingelaufenen Wanderschuhe eine nagelneue Sohle, sind bequem und wie neu. Hochwertige Reparatur. Danke, danke, danke!',
  'Great job! You just made the hole invisible. It was super...': 'Tolle Arbeit! Ihr habt das Loch einfach unsichtbar gemacht. Es war super einfach eure Website zu bedienen, sobald man es einmal ausprobiert hat, hat man gleich Lust auf mehr!',
  'Please contact us via the': 'Bitte kontaktiere uns über die Seite',
  'contact us': 'Kontakt',
  'page to make an alternative arrangement.': 'um eine alternative Vereinbarung zu treffen.',
  'Please enter a valid zip code for city, or change your location in the User Account area.':
    'Bitte verwende eine gültige Postleitzahl für city, oder ändere deinen Standort im User Account Bereich.',
  'Please enter a valid zip code for your city':
    'Derzeit bieten wir keine Tür zu Tür Lieferung an Deinem Standort an. Bitte gib Deine Bestellung über <0>“Schätzung Anfordern”</0> auf und sende uns Deine Reparaturen per Post!',
  'Please enter a valid country':
  'Für Bestellungen außerhalb Deutschlands kontaktiere uns über das Formular für Kostenvoranschläge.',
  'First name required': 'Vorname erforderlich',
  'Last name required': 'Nachname erforderlich',
  'Images required': 'Bilder erforderlich',
  'Address required': 'Adresse erforderlich',
  'City required': 'Stadt erforderlich',
  'Zip code required': 'Postleitzahl erforderlich',
  'The phone number is not valid, please use a valid format': "Die Telefonnummer ist nicht gültig, bitte verwende das Format: '+4917669423506'",
  'Back to the Homepage': 'Zurück zur Startseite',
  "This page doesn't exist.": 'Diese Seite existiert nicht.',
  'Please accept our terms': 'Bitte akzeptieren Sie unsere AGB',
  'Discount Code': 'Rabattcode',
  'discount is activated': 'rabatt wird aktiviert',
  Discount: 'Rabatt',
  'Please select delivery time frame': 'Bitte wählen Sie Zustellzeit',
  'Invalid input': 'Ungültige Eingabe',
  'Submit Update': 'Update senden',
  'Delivery Address': 'Zustelladresse',
  'Thank you': 'Danke',
  'has been updated': 'wurde aktualisiert',
  'You will receive an email confirming these changes.': 'Du erhältst in Kürze eine E-Mail, die diese Änderungen bestätigt.',
  'The details of your order has been updated': 'Die Details Ihrer Bestellung wurden aktualisiert',
  'Status: Active': 'Status: Aktiv',
  'Delivery Time': 'Zustellzeit',
  timeGapNote: 'Bitte beachte, dass zwischen der Abhol- und der Zustellzeit 7 Tage liegen müssen. Möglicherweise musst Du die Zustellzeit zuerst ändern.',
  'Pick Up Time': 'Abholzeit',
  Date: 'Datum',
  'Pick Up Address': 'Adresse abholen',
  'Email address cannot be empty': 'E-Mail-Adresse darf nicht leer sein',
  Order: 'Bestellen',
  'Please select pick up time frame': 'Bitte wählen Sie Abholzeitrahmen',
  'Please click on the button below and sign up again': 'Bitte klicken Sie auf den Button unten und melden Sie sich erneut an',
  'Name required': 'Name erforderlich',
  'Not Allowed': 'Nicht erlaubt',
  'Your order cannot be edited withing 48 hours of the pickup or delivery date.':
    'Ihre Bestellung kann nicht innerhalb von 48 Stunden nach dem Abhol- oder Lieferdatum bearbeitet werden.',
  'faq-header': 'WIE FUNKTIONIERT ES?',
  'faq-subtext': 'Hier findest Du alle wichtigen Informationen zu unserem Online Modereparaturservice.',
  'blog-header': 'Unser blog',
  'blog-subheader': 'Finde nützliche Reparaturtipps und lese inspirierende Geschichten aus der Reparatur Community.',
  'imprint-header': 'IMPRESSUM',
  'privacy-policy-header': 'DATENSCHUTZERKLÄRUNG',
  'terms-header': 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
  'jobs-header': 'WERDE TEIL DES REPAIR REBELS TEAMS!',
  'tailors-list-header': 'UNSERE PARTNER VOR ORT',
  'tailors-list-subheader':
    'Unsere erfahrenen Local Heroes machen alles möglich. Jeder von ihnen hat seine eigene Spezialität und bringt Erfahrungen aus aller Welt mit. Unsere Schneider, Schuster und Goldschmiede können jedes Modestück reparieren!',
  'Our standard': 'Unsere standard',
  DorToDoor: 'TÜR-ZU-TÜR LIEFERUNGEN IN DÜSSELDORF & KÖLN',
  BoxesAre: 'Tüten sind',
  GermanWideShiping: 'DEUTSCHLANDWEITER VERSAND PER POST',
  FreeTransport: 'KOSTENLOSER VERSAND FÜR BESTELLUNGEN AB 145€',
  TypicalRepairTime: 'TYPISCHE REPARATURZEIT 7-10 TAGE',
  OrderReceipts: 'Reparaturen - Lieferung',
  Value: 'Wert',
  ShippingReceipts: 'Reparaturen - Versand',
  GiftVouchers: 'GESCHENKGUTSCHEINE',
  'Looking for a unique and meaningful': 'Suchst Du nach einem einzigartigen Geschenk für Deine Liebsten?',
  'A small gift with': 'Ein kleines Geschenk mit großer Wirkung! Durch die Entscheidung für Reparaturen tun wir nicht nur etwas Gutes für unsere Lieblingsstücke, sondern wir schützen auch die Umwelt und unterstützen das lokale Handwerk.',
  'A GIFT OF REPAIR SHOWS THAT YOU CARE!': 'A GIFT OF REPAIR SHOWS THAT YOU CARE!',
  'How does It Work': 'How Does It Work',
  'The Repair Rebels repair voucher': 'Der Repair Rebels Reparaturgutschein ist das perfekte Geschenk für deine Liebsten. Nach der Buchung erhältst du den Gutschein mit individuellem Code per E-Mail. Du kannst den Gutschein direkt selbst ausdrucken und verschenken oder ihn per E-Mail weiterleiten. Unsere Gutscheine sind nur online einlösbar und sind 3 Jahre gültig. Die Frist beginnt am Ende des Jahres, in dem der Geschenkgutschein gekauft wurde.',
  'By the way': 'Übrigens: Der Gutschein wird ausschließlich per E-Mail verschickt, nicht postalisch. Und bei der Buchung einer Reparatur muss der gesamte Gutscheinwert verwendet werden. ',
  VoucherReceipts: 'Geschenkgutscheine',
  Big: 'ODER GROß',
  'We work with local repair shops':
    'Wir arbeiten mit lokalen Reparaturwerkstätten zusammen. Diese sind meist spezialisiert auf die Reparaturen von Schuhen und Taschen oder Bekleidung. Wir bitten dich daher, falls du sowohl Schuhe (oder Taschen) als auch Bekleidung reparieren lassen möchtest, diese bei der Abholung in separaten Tüten abzugeben.',
  ...appProductsI18nDe,
  ...appTailorsI18nDe,
  ...seoI18nDe,
};

export default appI18nDe;
