const getBase64 = async (file: Blob): Promise<string | undefined> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
  });
};

async function getBase64New(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string); 
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export { getBase64,  getBase64New};
