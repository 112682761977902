import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import orangeBackground from './orange-background.svg';
import whiteBackground from './white-background.svg';
import { Link } from '@reach/router';
import { getPage } from 'utils/pages';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);

  return {
    slideWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    textBlock: {
      marginTop: s(5),
    },
    subtitle: {
      color: '#ffffff',
      textTransform: 'uppercase',
      fontSize: s(5),
      marginBottom: s(3),
      [theme.breakpoints.down('lg')]: {
        fontSize: s(4),
      },
    },
    mainTitle: {
      fontSize: s(2),
      color: '#ffffff',
      textTransform: 'initial', 
    },
    buttonsBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: '23vmin',
      gap: s(2),
      [theme.breakpoints.down(380)]: {
        marginBottom: '5vmin',
        gap: '1vmin'
      },
    },
    repairLink: {
      width: '100%',
      maxWidth: '272px',
      fontSize: s(2.5),
      fontWeight: 700,
      color: '#ffffff',
      backgroundImage: `url(${orangeBackground})`,
      padding: `${s(1.5)}px ${s(0)}px`,
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      },
    },
    esimateLink: {
      width: '100%',
      maxWidth: '272px',
      fontSize: s(2.5),
      fontWeight: 700,
      color: '#000000',
      background: `url(${whiteBackground})`,
      padding: `${s(1.5)}px ${s(0)}px`,
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#000000',
        scale: 1.1,
      },
    },
  };
});

export const BannerSliderItemMobile = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className="container" style={{ height: '100%' }}>
      <Box textAlign={'center'} className={classes.slideWrapper}>
        <div className={classes.textBlock}>
          <h2 className={classes.subtitle}>{t('Still in love with your favourites')}</h2>
          <h1 className={classes.mainTitle}>{t('Let us take')}</h1>
        </div>
        <div className={classes.buttonsBlock}>
          <Link to={`${getPage('product')}${getPage('select')}`} className={classes.repairLink}>
            {t('BookRepair')}
          </Link>
          <Link to="/estimate/new" className={classes.esimateLink}>
            {t('GetAnEstimate')}
          </Link>
        </div>
      </Box>
    </div>
  );
};
