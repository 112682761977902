import React from 'react';
import { Box, Container, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import ProductDetailedButton, { ProductDetailedButtonLink } from '../DetailedButton/DetailedButton';
import AppNotFound from '../../../components/NotFound';
import { sendEvent } from '../../../utils/google-analytics';
import { Product } from '../../../types';
import RunningLine from 'components/RunningLine';
import Testimonials from '../../../Homepage/components/Testimonials';
import Trustpilot from '../../../components/Trustpilot';
import Gallery from '../DetailsStep/Gallery';
import { Link } from '@reach/router';
import { getPage } from '../../../utils/pages';

// styles
const useStyles = makeStyles(() => {
  return {
    list: {
      listStyle: 'none',
      padding: 0,
    },
  };
});

// props; exporting to tests
export interface ProductRepairStepViewProps {
  repairs: Product[];
  onRepairSelect(repair: Product): void;
  categoryId?: string;
  typeId?: string;
  backLinkHref?: string;
}

/**
 * Product repair step presentational component.
 */
export default function ProductRepairStepView({ repairs, onRepairSelect, categoryId, typeId, backLinkHref = '..' }: ProductRepairStepViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const headingHtmlId = 'ProductRepairStep-heading';
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const categoryImages = repairs[0]?.productType?.images || [];

  if (repairs.length === 0) {
    return <AppNotFound />;
  }

  const onProductDetailClick = (x: Product) => {
    onRepairSelect(x);
    //@ts-ignore
    sendEvent('repair', `repair_garment_selected-garment_${x.name}`, x.name);
  };

  function renderButtons() {
    const buttons = repairs.map((x, i) => (
      <Box component="li" key={i} mb={2}>
        <ProductDetailedButton onClick={() => onProductDetailClick(x)} description={x.shortDescription} {...x} />
      </Box>
    ));
    buttons.push(<ProductDetailedButtonLink key={repairs.length} />);
    return buttons;
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="xl">
        <div style={{display: 'flex', flexWrap: isMobile ? 'wrap' : 'nowrap', gap: '30px', padding: '45px 0 90px' }}>
          <Box width={'100%'}>
            <ProductHeader categoryId={categoryId} typeId={typeId} backLinkHref={backLinkHref} />
            <Box mb={2} />
            <Typography className="with-bar" id={headingHtmlId} variant="h1">
              {t('Select the type of repair')}
            </Typography>
            <Box mb={4} />
            <Box style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: isMobile ? 'column-reverse' : 'row'}}>
              <Box width={isMobile ? '100%' : '50%'}>
                <ul aria-labelledby={headingHtmlId} className={classes.list}>
                  {renderButtons()}
                </ul>
                <Box mb={6} />
                <Typography  style={{marginLeft: '12px'}} className="with-bar"  variant="h3">
                  <Link style={{color: '#000'}} to={getPage('faq')} >
                    {t('How It Works')}
                  </Link>
                </Typography>
              </Box>
              { !!categoryImages?.length &&
                <Box width={isMobile? '100%' : '46%'} style={{paddingBottom: isMobile ? '30px' : ''}}>
                  <Gallery images={categoryImages} hideThumbs={true}/>
                </Box>
              }
            </Box>
          </Box>

        </div>
      </Container>
      <Container>
        <Testimonials />

        <Trustpilot/>
      </Container>
    </Box>
  );
}
