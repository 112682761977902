import React from 'react';
import classes from './Trustpilot.module.css';
import TrustpilotImg from './Trustpilot.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Trustpilot() {
  const trustpilotLink = 'https://www.trustpilot.com/review/repair-rebels.com';

  return(
    <div className='container'>
      <div className="row">
        <div className={classes.trustpilotContainer}>
          Excellent
          <a href={trustpilotLink} target="_blank" rel="noopener noreferrer">
            <LazyLoadImage className={classes.image} alt="Trustpilot" src={TrustpilotImg} width={160} height={'auto'} effect="blur" />
          </a>
        </div>
      </div>
    </div>
  )
}
