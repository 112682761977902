import i18n from '../i18n';
import { blogItemsDe, blogItemsEn } from '../Blog/data/items';
import { Product } from 'types';

const PAGES = {
  en: {
    aboutUs: 'about-us',
    auth: 'auth',
    blogList: 'blog',
    blogPost: 'blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'create-password',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'forgot-password',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'imprint',
    jobs: 'jobs',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-delivery/:customerId/:orderId',
    orderEditNotAllowed: 'edit-not-allowed',
    orderEditPickUp: 'edit-pickup/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'privacy-policy',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registration',
    resetPassword: 'reset-password',
    select: 'select',
    tailorList: 'our-partners',
    tailorProfile: 'our-partners/:tailorName',
    terms: 'terms-and-conditions',
    verifyEmail: 'verify-email',
    voucher: 'voucher',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'brand-partnerships',
    forCraftsmen: 'for-craftsmen',
    tailorClub: 'tailor-club',
    b2bClub: 'b2b-club',
  },
  de: {
    aboutUs: 'ueber-uns',
    auth: 'auth',
    blogList: 'unser-blog',
    blogPost: 'unser-blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'passwort-erstellen',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'passwort-vergessen',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'impressum',
    jobs: 'karriere',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-lieferung/:customerId/:orderId',
    orderEditNotAllowed: 'bearbeiten-nicht-erlaubt',
    orderEditPickUp: 'bearbeiten-abholung/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'datenschutzerklaerung',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registriere-dich',
    resetPassword: 'passwort-zurücksetzen',
    select: 'select',
    tailorList: 'unsere-partner',
    tailorProfile: 'unsere-partner/:tailorName',
    terms: 'allgemeine-geschaeftsbedingungen',
    verifyEmail: 'verify-email',
    voucher: 'gutschein',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'marken-partnerschaften',
    forCraftsmen: 'fur-handwerk',
    tailorClub: 'tailor-club',
    b2bClub: 'b2b-club',
  },
};

const getRoute = (pageName: string) => {
  return PAGES[i18n.language][pageName];
};

const getPage = (pageName: string) => {
  return `/${PAGES[i18n.language][pageName]}`;
};

const generateDynamicRoutes = (
  prevLanguage: 'de' | 'en',
  language: 'de' | 'en',
  products: Product[] | null
): {
  dynamicRoutes: Record<string, string>;
  simpleRoutes: Record<string, string>;
  complexRoutes: Record<string, string>;
  complexTrippleRoutes: Record<string, string>;
} => {
  if (!products) return { dynamicRoutes: {}, simpleRoutes: {}, complexRoutes: {}, complexTrippleRoutes: {} };

  const dynamicRoutes: Record<string, string> = {};
  const simpleRoutes: Record<string, string> = {};
  const complexRoutes: Record<string, string> = {};
  const complexTrippleRoutes: Record<string, string> = {};

  products.forEach((product) => {
    if (product.seo && product.productCategory?.seo && product.productType?.seo) {
      const prevSeo = product.seo[prevLanguage];
      const newSeo = product.seo[language];
      const prevCategory = product.productCategory.seo[prevLanguage];
      const newCategory = product.productCategory.seo[language];
      const prevType = product.productType.seo[prevLanguage];
      const newType = product.productType.seo[language];

      // Basic dynamic mappings
      dynamicRoutes[prevSeo] = newSeo;
      dynamicRoutes[prevCategory] = newCategory;
      dynamicRoutes[prevType] = newType;

      // Simple Routes (e.g., direct mappings)
      simpleRoutes[`/products/${prevSeo}`] = `/products/${newSeo}`;

      // Complex Routes (e.g., category + product)
      complexRoutes[`/categories/${prevCategory}/products/${prevSeo}`] = `/categories/${newCategory}/products/${newSeo}`;

      // Complex Triple Routes (e.g., category + type + product)
      complexTrippleRoutes[`/categories/${prevCategory}/types/${prevType}/products/${prevSeo}`] =
        `/categories/${newCategory}/types/${newType}/products/${newSeo}`;
    }
  });

  return { dynamicRoutes, simpleRoutes, complexRoutes, complexTrippleRoutes };
};

const getLanguageChangePageRedirect = (prevLanguage: 'de' | 'en', language: 'de' | 'en', pathname: string, ) => {
  // if (pathname.includes('product')) {
  //   return pathname;
  // }
  const currentPageKey = Object.entries(PAGES[prevLanguage]).reduce((prev, [key, value]) => {
    if (key !== 'homepage' && pathname.includes(value)) {
      return key;
    }
    return prev;
  }, '');

  if (currentPageKey) {
    const newPathRoot = PAGES[language][currentPageKey];

    if (currentPageKey === 'blogList') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [_, blogItemName] = pathname.split(PAGES[prevLanguage][currentPageKey]);
      blogItemName = blogItemName.substring(1);
      const englishBlogs = blogItemsEn.map(({ id }) => id);
      const germanBlogs = blogItemsDe.map(({ id }) => id);

      if (language === 'de') {
        return `/${newPathRoot}/${germanBlogs[englishBlogs.indexOf(blogItemName)]}`;
      } else if (language === 'en') {
        return `/${newPathRoot}/${englishBlogs[germanBlogs.indexOf(blogItemName)]}`;
      }
    }

    return pathname.replace(PAGES[prevLanguage][currentPageKey], PAGES[language][currentPageKey]);
  }

  return '/';
};

const getLanguageChangePageProductRedirect = (
  prevLanguage: 'de' | 'en',
  language: 'de' | 'en',
  pathname: string,
  products: Product[] | null
) => {
  // console.log('=== Language Change Redirect Debugging ===');
  // console.log('Previous Language:', prevLanguage);
  // console.log('New Language:', language);
  // console.log('Current Pathname:', pathname);
  // console.log('Products:', products);

  if (!products) {
    console.warn('No products found, redirecting to /');
    return '/';
  }

  const { dynamicRoutes, simpleRoutes, complexRoutes, complexTrippleRoutes } = generateDynamicRoutes(prevLanguage, language, products);
  console.log('Generated Routes:', { dynamicRoutes, simpleRoutes, complexRoutes, complexTrippleRoutes });

  console.log('Checking Complex Triple Routes...');
  for (const [prevRoute, newRoute] of Object.entries(complexTrippleRoutes)) {
    if (pathname.includes(prevRoute)) {
      console.log(`Matched Complex Triple Route: ${prevRoute} -> ${newRoute}`);
      return pathname.replace(prevRoute, newRoute);
    }
  }

  for (const [prevRoute, newRoute] of Object.entries(complexRoutes)) {
    if (pathname.includes(prevRoute)) {
      console.log(`Matched Complex Route: ${prevRoute} -> ${newRoute}`);
      return pathname.replace(prevRoute, newRoute);
    }
  }

  console.log('Checking Simple Routes...');
  for (const [prevRoute, newRoute] of Object.entries(simpleRoutes)) {
    if (pathname.includes(prevRoute)) {
      console.log(`Matched Simple Route: ${prevRoute} -> ${newRoute}`);
      return pathname.replace(prevRoute, newRoute);
    }
  }

  let newPath = pathname;
  console.log('Checking Dynamic Routes...');
  for (const [prevRoute, newRoute] of Object.entries(dynamicRoutes)) {
    if (newPath.includes(prevRoute)) {
      console.log(`Matched Dynamic Route: ${prevRoute} -> ${newRoute}`);
      newPath = newPath.replace(prevRoute, newRoute);
    }
  }

  console.log('Final Computed Path:', newPath !== pathname ? newPath : '/');
  return newPath !== pathname ? newPath : '/';
};

// const normalizeString = (str: string): string => str.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); / Old implementation

const localizePath = (
  path: string,
  currentLanguage: string,
  blogItemsEn: { id: string }[],
  blogItemsDe: { id: string }[]
): string => {
 
  const sourceLanguage = currentLanguage === 'en' ? 'de' : 'en';

  const sourcePages = PAGES[sourceLanguage];
  const targetPages = PAGES[currentLanguage];

  const reverseMapping: Record<string, string> = Object.entries(sourcePages).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  );

  const pathSegments = path.split('/').filter(Boolean);

  const firstSegment = pathSegments[0];
  const currentPageKey = Object.entries(sourcePages).find(([_, value]) => value === firstSegment)?.[0] || '';

  if (currentPageKey) {
    const newPathRoot = targetPages[currentPageKey];

    if (currentPageKey === 'blogList') {

      let [_, blogItemName] = path.split(sourcePages[currentPageKey]);
      blogItemName = blogItemName.substring(1);

      const englishBlogs = blogItemsEn.map(({ id }) => id);
      const germanBlogs = blogItemsDe.map(({ id }) => id);

      if (currentLanguage === 'de') {
        return `/${newPathRoot}/${germanBlogs[englishBlogs.indexOf(blogItemName)]}`;
      } else if (currentLanguage === 'en') {
        return `/${newPathRoot}/${englishBlogs[germanBlogs.indexOf(blogItemName)]}`;
      }
    }
    return path.replace(sourcePages[currentPageKey], targetPages[currentPageKey]);
  }

  const localizedSegments = pathSegments.map((segment) => {
    const key = reverseMapping[segment];
    return key ? targetPages[key] : segment; 
  });

  return `/${localizedSegments.join('/')}`;
};


export { PAGES, getPage, getRoute, getLanguageChangePageRedirect, localizePath, getLanguageChangePageProductRedirect };