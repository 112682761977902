import {
  Box,
  Button,
  Container,
  FormControlLabel,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import AppCartDisabledFrame from '../../../components/CartDisabledFrame';
import LoadingButton from '../../../components/LoadingButton';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import { AmountType } from '../../../Cart/interfaces/item';
import { sendEvent } from '../../../utils/google-analytics';
import { removePayPalGuard } from '../../../utils/remove-paypal-guard';
import { Product } from '../../../types';
import RunningLine from 'components/RunningLine';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setCreationTime, setVoucherCart } from 'Cart/store/actions';
import i18n from 'i18n';
import { uploadImagesFiles } from 'Estimation/store/actions';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';
import ApiContext from 'api/context';
import Gallery from './Gallery';
import Testimonials from '../../../Homepage/components/Testimonials';
import Trustpilot from '../../../components/Trustpilot';
// env
const cartEnabled: boolean = process.env.REACT_APP_CART_ENABLED === 'true';
/*const cartEnabled = false;  // HARDCODE*/
const MAX_VIDEO_SIZE = 50 * 1024 * 1024;
// props; exporting to tests
export interface ProductDetailsStepFormProps {
  product: Product;
  onSubmit(amount?: number, comment?: string, creationTime?: number, images?: string[]): Promise<void>;
  backLinkHref?: string;
}

export interface RepairImage {
  file: File;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileContent: string;
  creationTime: number;
}

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },

    '& svg': {
      fill: '#000000',
      width: '40px',
      height: '40px',
    },

    '&:hover': {
      background: 'transparent',
    },
  },
}));

/**
 * Product "Further Details" presentational form component.
 */
export default function ProductDetailsStepForm({ product, onSubmit, backLinkHref = '..' }: ProductDetailsStepFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const maxUploads = 10;
  const langCookie = cookies.get('language') || '';
  const headingHtmlId = 'ProductDetailsStepForm-heading';
  const commentFieldHtmlId = 'ProductDetailsStepForm-commentField';
  const categoryId = product.productCategory.seo?.[i18n.language];
  const typeId = product.productType.seo?.[i18n.language];
  const repairId = product.seo?.[i18n.language];
  const priceCent = product.priceCent;
  const productImages = product.images;
  const [comment, setComment] = useState('');
  const [newCreationTime] = useState(Date.now());
  const [repairImages, setRepairImages] = useState<string[]>([]);
  const api = useContext(ApiContext);
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  let initialAmount = 1;
  if (product.input?.type === AmountType.LENGTH) {
    initialAmount = 0;
  }

  const [amount, setAmount] = useState(initialAmount);
  const [loading, setLoading] = useState(false);
  const { detailedDescription } = product;

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  const compressImage = async (file: File): Promise<File> => {
    const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };
    return await imageCompression(file, options);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = Array.from(e.target.files ?? []);

    if (files.length === 0) {
      setLoading(false);
      return;
    }

    try {
      const filePromises = files.map(async (file, index) => {
        console.log(`Processing file #${index + 1}:`, {
          name: file.name,
          type: file.type,
          size: file.size,
        });

        const mimeType = file.type.toLowerCase();
        const fileName = file.name.toLowerCase();

        if (mimeType === 'image/heic' || fileName.endsWith('.heic') || mimeType === '') {
          try {
            const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
            const convertedFile = new File([blob as BlobPart], fileName.replace(/\.heic$/, '.jpeg'), { type: 'image/jpeg' });
            return uploadToAws(convertedFile);
          } catch (error) {
            console.error('Error converting HEIC to JPEG', error);
            return null;
          }
        }

        if (mimeType.startsWith('image/')) {
          try {
            const compressedFile = await compressImage(file);
            return uploadToAws(compressedFile);
          } catch (error) {
            console.error('Error compressing image', error);
            return null;
          }
        }

        if (mimeType.startsWith('video/')) {
          if (file.size > MAX_VIDEO_SIZE) {
            console.warn('File size exceeds the maximum allowed for videos');
            return null;
          }
          return uploadToAws(file);
        }

        return null;
      });

      const uploadedUrls = await Promise.all(filePromises);
      const validUrls = uploadedUrls.filter((url): url is string => url !== null);

      setRepairImages((prevImages) => [...prevImages, ...validUrls]);

    } catch (error) {
      console.error('Error uploading files to AWS:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadToAws = async (file: File): Promise<string | null> => {
    setLoading(true)
    try {
      const response = await api.product.uploadImageWithAws(file);
      if (response?.uploadedFile) {
        return response.uploadedFile;
      } else {
        console.error('Failed to upload file:', file.name);
        return null;
      }
    } catch (error) {
      console.error('Error uploading file to AWS:', error);
      return null;
    } finally {
      setLoading(false)
    }
  };

  function handleAmountChange(e) {
    setAmount(e.target.value);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const setVoucherInactive = false;

    dispatch(setVoucherCart(setVoucherInactive));
    dispatch(uploadImagesFiles());
    localStorage.setItem('voucherItem', JSON.stringify(setVoucherInactive));

    try {
      // @ts-ignore
      await onSubmit(Number(amount), comment, newCreationTime, repairImages);
      dispatch(setCreationTime(newCreationTime));
    } catch (error) {
      console.error('Failed to submit product:', error);
    } finally {
      setLoading(false);
      removePayPalGuard();
      sendEvent('repair', 'add_repair_to_cart');
    }
  };

  function renderAmountField() {
    if (product.input?.type === AmountType.NO_INPUT) {
      return null;
    }

    const amountFieldHtmlId = 'ProductDetailsStepForm-amountField';
    const step = product.input?.default ?? 1;
    return (
      <>
        <Typography component="label" gutterBottom htmlFor={amountFieldHtmlId}>
          {product.input?.text ? t(product.input?.text) : t('Amount')}
        </Typography>
        :
        <Box display="flex" alignItems="center" mb={3}>
          <Box width={{ xs: '50%', sm: '33%' }}>
            <TextField
              fullWidth
              id={amountFieldHtmlId}
              inputProps={{
                min: +step,
                step: +step,
                style: { textAlign: 'center' },
              }}
              name="quantity"
              onChange={handleAmountChange}
              required
              //required={quantityInput.required}
              size="small"
              type="number"
              value={amount}
              variant="outlined"
            />
          </Box>
          <Box mr={1} />
          <Typography variant="body1">{product.input?.unit}</Typography>
        </Box>
      </>
    );
  }

  function renderImageField() {
    const handleFileChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e);
    };

    return (
      <>
        <FormControlLabel
          style={{
            marginBottom: 0,
          }}
          control={<input id="file-upload" type="file" accept="image/*, video/mp4" onChange={handleFileChangeWrapper} style={{ display: 'none' }} multiple />}
          label={
            <Button disableRipple color="secondary" component="div" className={classes.uploadButton}>
              <AddAPhotoIcon /> + {t('Upload Image')} ( {repairImages.length} / {maxUploads} )*
            </Button>
          }
        />
        <Typography>{t('ImageProductUploadText')}</Typography>
        <Box mb={4} />
        <Box>
          <Typography color="secondary" variant="caption">
            {t('fields_with_asterisks')}
          </Typography>
        </Box>
        <Box mb={2} />
      </>
    );
  }

  function renderWhatsApp() {
    if (cartEnabled) {
      return (
        <>
          <Box mb={6} />
          <AppWhatsApp />
        </>
      );
    }
    return (
      <>
        <Box mb={4} />
        <AppCartDisabledFrame />
      </>
    );
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="xl" style={{paddingBottom: '100px'}}>
        <ProductHeader categoryId={categoryId} typeId={typeId} repairId={repairId} priceCent={priceCent} backLinkHref={backLinkHref} />
        <Box mb={3} />
        <Typography className="with-bar" id={headingHtmlId} variant="h1">
          {t('Further Details')}
        </Typography>
        <Box mb={3} />
        <form aria-labelledby={headingHtmlId} onSubmit={handleSubmit}>
          <Paper style={{display: 'flex', flexWrap: isMobile ? 'wrap' : 'nowrap', gap: '30px' }}>
            <Box width={isMobile ? '100%' : !!productImages?.length ? '50%' : '100%'}>
              {renderAmountField()}
              <Typography component="label" gutterBottom htmlFor={commentFieldHtmlId}>
                {t('Comments')}
              </Typography>
              :
              <TextField
                fullWidth
                id={commentFieldHtmlId}
                multiline
                name="comment"
                onChange={handleCommentChange}
                rows={5}
                size="small"
                value={comment}
                variant="outlined"
              />
              {detailedDescription && (
                <>
                  <Box mb={3} />
                  <Typography variant="body1">{(!langCookie ? detailedDescription.de : detailedDescription[langCookie]).replaceAll('\\u003a', ':')}</Typography>
                  <Box mb={4} />
                </>
              )}
              {renderImageField()}
            </Box>
            { !!productImages?.length &&
              <Box width={isMobile? '100%' : '46%'}>
                <Gallery images={productImages} />
              </Box>
            }
          </Paper>
          <Box mb={4} />
          <Box textAlign="center">
            <LoadingButton color="secondary" disabled={!cartEnabled} loading={loading} type="submit" variant="contained">
              {t('Add to Cart')}
            </LoadingButton>
          </Box>
        </form>
      </Container>
      <Container>
        <Testimonials />

        <Trustpilot/>
      </Container>
    </Box>
  );
}
