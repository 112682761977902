import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import banner1 from './banner1.png';
import banner3 from './banner3.png';
import banner4 from './banner4.png';
import banner7 from './banner7.png';
import banner8 from './banner8.png';
import mobileBanner1 from './mobile-assets/banner1.png';
import mobileBanner2 from './mobile-assets/banner2.png';
import mobileBanner3 from './mobile-assets/banner3.png';
import mobileBanner4 from './mobile-assets/banner4.png';
import mobileBanner5 from './mobile-assets/banner5.png';
import mobileBannerSecond from './mobile-assets/banner1-second-changed.png';
import SwiperCore, { Mousewheel, Autoplay } from 'swiper';
import styles from './BannerSlider.module.css';
import { BannerSliderItem } from './BannerSliderItem';
import { useMediaQuery } from '@material-ui/core';
import { BannerSliderItemMobile } from './BannerSliderItemMobile';

const BannerSlider = () => {
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const images = [
    {
      image: banner4,
      position: 'center',
    },
    {
      image: banner8,
      position: 'center',
    },
    {
      image: banner7,
      position: 'center',
    },
    {
      image: banner3,
      position: 'center',
    },
    {
      image: banner1,
      position: 'center',
    },
  ];

  const mobileImages = [
    {
      image: mobileBannerSecond,
      position: 'initial',
    },
    {
      image: mobileBanner2,
      position: 'center',
    },
    {
      image: mobileBanner3,
      position: 'center',
    },
    {
      image: mobileBanner4,
      position: 'center',
    },
    {
      image: mobileBanner5,
      position: 'center',
    },
  ];
  const bannerImages = isMobile ? mobileImages : images;

  SwiperCore.use([Mousewheel, Autoplay]);

  return (
    <Swiper
      loop
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      effect="fade"
      speed={1000}
      className={styles.swiper}
    >
      {bannerImages.map((image, index) => (
        <SwiperSlide
          key={index}
          style={{ backgroundImage: `url(${image.image})`, backgroundPosition: image.position }}
          className={isMobile ? styles.slideMobile : styles.slide}
        >
          {isMobile ? <BannerSliderItemMobile /> : <BannerSliderItem />}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSlider;
