import React from 'react';
import { Box, Container, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import ProductDetailedButton, { ProductDetailedButtonLink } from '../DetailedButton/DetailedButton';
import AppNotFound from '../../../components/NotFound';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import { groupByType } from '../../utils';
import { sendEvent } from '../../../utils/google-analytics';
import { Product, ProductType } from '../../../types';
import RunningLine from 'components/RunningLine';
import i18n from 'i18n';
import Testimonials from '../../../Homepage/components/Testimonials';
import Trustpilot from '../../../components/Trustpilot';
import Gallery from '../DetailsStep/Gallery';
import classes from '../../../components/Menu/Menu.module.css';
import { getPage } from '../../../utils/pages';

// styles
const useStyles = makeStyles(() => {
  return {
    list: {
      listStyle: 'none',
      padding: 0,
    },
  };
});

// props
interface ProductTypeStepRouteProps {
  seo: string;
}
interface ProductTypeStepProps extends RouteComponentProps<ProductTypeStepRouteProps> {
  products: Product[];
}

/**
 * Product type step component.
 */
export default function ProductTypeStep({ products, seo }: ProductTypeStepProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headingHtmlId = 'ProductTypeStep-heading';
  const types = groupByType(products, seo ?? '');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const categoryImages = types[0]?.category?.images || [];

  if (!seo || types.length === 0) {
    return <AppNotFound />;
  }

  const onProductDetailClick = (x: ProductType) => {
    //@ts-ignore
    sendEvent('repair', `item_type_selected-type_${x.title}`, x.title);
    navigate(`./${seo}/${x.seo?.[i18n.language]}`);
  };

  function renderButtons() {
    const buttons = types.map((x, i) => (
      <Box component="li" key={i} mb={2}>
        <ProductDetailedButton onClick={() => onProductDetailClick(x)} name={x.title} {...x} />
      </Box>
    ));
    buttons.push(<ProductDetailedButtonLink key={types.length} />);
    return buttons;
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="xl">
        <div style={{display: 'flex', flexWrap: isMobile ? 'wrap' : 'nowrap', gap: '30px', padding: '45px 0 90px' }}>
          <Box width={'100%'}>
            <ProductHeader categoryId={seo} />
            <Box mb={1} />
            <Typography className="with-bar" id={headingHtmlId} variant="h1">
              {t('Select the item type')}
            </Typography>
            <Box mb={4} />
            <Box style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: isMobile ? 'column-reverse' : 'row'}}>
              <Box width={isMobile ? '100%' : '50%'}>
                <ul aria-labelledby={headingHtmlId} className={classes.list}>
                  {renderButtons()}
                </ul>
                <Box mb={6} />
                  <Typography  style={{marginLeft: '12px'}} className="with-bar"  variant="h3">
                    <Link style={{color: '#000'}} to={getPage('faq')} >
                      {t('How It Works')}
                    </Link>
                  </Typography>
              </Box>
              { !!categoryImages?.length &&
                <Box width={isMobile? '100%' : '46%'} style={{paddingBottom: isMobile ? '30px' : ''}}>
                  <Gallery images={categoryImages} hideThumbs={true}/>
                </Box>
              }
            </Box>

          </Box>
        </div>
      </Container>
      <Container>
        <Testimonials />

        <Trustpilot/>
      </Container>
    </Box>
  );
}
