import React, { useState } from 'react';
import { Swiper, SwiperSlide  } from 'swiper/react';
import SwiperCore, { Thumbs, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { useMediaQuery } from '@material-ui/core';

interface GalleryProps {
  images: string[];
  hideThumbs?: boolean;
}

export default function Gallery({images, hideThumbs = false}: GalleryProps) {
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  SwiperCore.use([Thumbs, Navigation]);

  const createSlides = (imageLink: string, id: number) => {
    return (
      <SwiperSlide key={id}>
        <img src={imageLink} alt={'slider-image'} style={{ width: '100%' }} />
      </SwiperSlide>
    )
  }

  return (
    <div>
      <Swiper
        loop
        pagination={{
          clickable: true,
        }}
        spaceBetween={10}
        navigation
        className="gallerySwiper"
        // @ts-ignore
        thumbs={{swiper: thumbsSwiper}}
      >
        {images.map((image, i) => createSlides(image, i))}
      </Swiper>
      {
        !hideThumbs &&
          <Swiper
            spaceBetween={10}
            slidesPerView={images.length}
            freeMode={true}
            watchSlidesProgress={true}
            className="myThumbsSwiper"
            onSwiper={setThumbsSwiper}
          >
            {images.map((image, i) => createSlides(image, i))}
          </Swiper>
      }

    </div>
  )
}
