import ProductApi from '../interfaces/api';
import appRequest from '../../utils/request';
import { getBase64 } from 'utils/getBase64';

/**
 * Product API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const productApiProd: ProductApi = {
  fetchProducts: async () => {
    const { data } = await appRequest('product/list');
    return data;
  },
  fetchProductById: async (_id) => {
    const { data } = await appRequest(`product/by-id/${_id}`);
    return data?.data ?? data;
  },
  uploadImageWithAws: async (file: File) => {
    const base64File = await getBase64(file);
    
    const { data } = await appRequest(`/order/upload-image`,{
      method: 'POST',
      data: JSON.stringify({
        file: base64File,
        filename: file.name,
        filetype: file.type,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  
    return data?.data ?? data;
  },
  deleteImageFromAws: async (urls: string[]) => {
    try {
      const { data } = await appRequest(`/order/delete-image`, {
        method: 'POST',
        data: JSON.stringify({ urls }),
        headers: { 'Content-Type': 'application/json' },
      });
  
      return data?.data ?? data;
    } catch (error) {
      console.error('Error deleting images:', error);
      throw error;
    }
  },
};

export default productApiProd;
