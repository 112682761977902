import ProductApi from '../interfaces/api';
import { Product } from '../../types';

async function fetchProducts() {
  return [];
}

/**
 * Product API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const productApiTest: ProductApi = {
  fetchProducts,
  fetchProductById: async (_id) => ({} as Product),
  uploadImageWithAws: async (_id) => ({} as any),
  deleteImageFromAws: async(urls: string[]) => {}
};

export default productApiTest;
