import React, { useContext, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import Cookies from 'js-cookie';
import i18n from '../../../i18n';
import ApiContext from '../../../api/context';
import classes from './LanguageSwitch.module.css';
import deLang from './deLang.svg';
import enLang from './enLang.svg';
import { useLocation, useNavigate } from '@reach/router';
import { getLanguageChangePageProductRedirect, getLanguageChangePageRedirect } from '../../../utils/pages';
import { SupportedLanguages } from '../../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { UserSlice } from '../../../Auth/store';
import { selectProductItems } from 'Product/store/selectors';

const isLanguageEnabled = process.env.REACT_APP_ENABLE_LANGUAGE_TOGGLE === 'true';

interface CommonLanguageSwitchProps {
  onChange?(): void;
}

export default function CommonLanguageSwitch({ onChange }: CommonLanguageSwitchProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateLanguage } = UserSlice.actions;
  const { href: currentPath } = useLocation();
  const [isDe, setDe] = useState(i18n.language === 'de');
  const api = useContext(ApiContext);
  const products = useSelector(selectProductItems);
  const isProductPage = currentPath.includes('/product/');

  async function toggleLanguage(language: SupportedLanguages) {
    await i18n.changeLanguage(language);
    Cookies.set('language', language, { expires: 30 });
    await window.OneTrust.changeLanguage(language);
    setDe(i18n.language === 'de');
    await api.auth.changePreferredLanguage(language);
    dispatch(updateLanguage(language));
    onChange && onChange();
    const path = isProductPage
      ? getLanguageChangePageProductRedirect(language === 'de' ? 'en' : 'de', language === 'de' ? 'de' : 'en', currentPath, products)
      : getLanguageChangePageRedirect(language === 'de' ? 'en' : 'de', language === 'de' ? 'de' : 'en', currentPath);

    await navigate(path);
  }

  if (!isLanguageEnabled) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-evenly">
      <IconButton className={`${classes.langBtn} ${isDe ? classes.active : classes.inactive}`} onClick={() => toggleLanguage(SupportedLanguages.de)}>
        <img alt="Deutsch" width={23} height={23} src={deLang} />
      </IconButton>
      <IconButton className={`${classes.langBtn} ${!isDe ? classes.active : classes.inactive}`} onClick={() => toggleLanguage(SupportedLanguages.en)}>
        <img alt="English" width={23} height={23} src={enLang} />
      </IconButton>
    </Box>
  );
}
